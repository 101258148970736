import { styled } from "../style/stitches.config";

export const SecurePaymentsDisclaimer = ({
  text,
  style,
}: {
  text: string;
  style?: any;
}) => {
  return (
    <StyledDisclaimerContainer style={style}>
      <div className="disclaimer-info">
        <div className="line">
          <div className="icon-wrapper">
            <img src="/images/shield.svg" alt="shield" />
          </div>
          <div className="text-wrapper">
            {`Secure payments powered by Stripe. ${text}`}
          </div>
        </div>
      </div>
    </StyledDisclaimerContainer>
  );
};

export const DisclaimerContainer = ({ style }: { style?: any }) => {
  return (
    <StyledDisclaimerContainer style={style}>
      <div className="disclaimer-info">
        <div className="line">
          <img src="/images/shield.svg" alt="shield" />
          <span className="strong">Secure payments.</span>You're only charged
          after session is confirmed.
        </div>
        <div className="line">
          <img src="/images/shield.svg" alt="shield" />
          <span className="strong">Flexible refunds.</span>
          Get money back for cancellations.
        </div>
      </div>
    </StyledDisclaimerContainer>
  );
};

const StyledDisclaimerContainer = styled("div", {
  width: "100%",
  paddingTop: "10px",
  fontSize: "12px",

  "& .disclaimer-info": {
    display: "flex",
    alignItems: "start",
    flexDirection: "column",
    marginTop: "0px",

    "& .line": {
      display: "flex",
      alignItems: "center",
      marginBottom: "8px",
      width: "100%",
      
      "& .icon-wrapper": {
        marginRight: "8px",
        display: "flex",
        alignItems: "center",
        flexShrink: 0,
        
        "& img": {
          width: "20px",
          height: "20px",
        },
      },
      
      "& .text-wrapper": {
        flex: "1",
        wordBreak: "normal",
        hyphens: "none",
        whiteSpace: "normal",
        lineHeight: "1.3",
      }
    },
  },
  "@mobile": {
    fontSize: "12px !important",
    
    "& .text-wrapper": {
      paddingRight: "10px",
    }
  },
});