import React, { useState } from 'react';
import { styled } from "~/ui/style/stitches.config";

export interface FAQItemProps {
  question: string;
  answer: string;
}

interface FAQProps {
  title?: string;
  faqData: FAQItemProps[];
  className?: string;
}

const FAQItem = ({ question, answer }: FAQItemProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-item">
      <div className="question-container" onClick={() => setIsOpen(!isOpen)}>
        <h4 className="question">{question}</h4>
        <span className="toggle-icon">{isOpen ? '−' : '+'}</span>
      </div>
      <div 
        className={`answer ${isOpen ? 'open' : ''}`}
        dangerouslySetInnerHTML={{ __html: answer }}
      />
    </div>
  );
};

export const FAQ = ({ title = "FAQ", faqData, className }: FAQProps) => {
  return (
    <StyledFAQ className={className}>
      <h3 className="faq-title">{title}</h3>
      <div className="faq-container">
        {faqData.map((item, index) => (
          <FAQItem 
            key={index} 
            question={item.question} 
            answer={item.answer} 
          />
        ))}
      </div>
    </StyledFAQ>
  );
};

const StyledFAQ = styled('div', {
  width: '100%',
  maxWidth: '500px',
  margin: '40px auto 0',
  padding: '0 20px',
  
  '.faq-title': {
    textAlign: 'center',
    fontSize: '40px',
    fontWeight: '500',
    fontFamily: "GorgeousBaby",
    marginBottom: '20px',
  },
  
  '.faq-container': {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    backgroundColor: "#ecdbcd",
    borderRadius: "18px",
    border: "1px solid #000",
    padding: "30px 20px"
  },
  
  '.faq-item': {
    border: '.5px solid rgba(0, 0, 0, 0.3)',
    borderRadius: '10px',
    overflow: 'hidden',
    transition: 'all 0.2s ease-in-out',
    
    '&:hover': {
      borderColor: 'rgb(0, 0, 0)',
    }
  },
  
  '.question-container': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    cursor: 'pointer',
    backgroundColor: 'hsla(0, 0.00%, 100.00%, 0.2)',
    transition: 'background-color 0.2s ease',
    
    '&:hover': {
      backgroundColor: 'rgb(255, 255, 255)',
    }
  },
  
  '.question': {
    fontSize: '13px',
    fontWeight: '500',
    margin: 0,

    '@mobile': {
        fontSize: "11px",
      }
  },
  
  '.toggle-icon': {
    fontSize: '18px',
    color: '#000',
    transition: 'transform 0.2s ease',
  },
  
  '.answer': {
    fontSize: '13px',
    lineHeight: '1.5',
    padding: '0 16px',
    height: '0',
    opacity: '0',
    overflow: 'hidden',
    transition: 'all 0.3s ease-in-out',


    '@mobile': {
        fontSize: "12px",
      },
    
    '&.open': {
      height: 'auto',
      opacity: '1',
      padding: '16px',
      borderTop: ".5px solid rgb(0, 0, 0, .3)"
    }
  },
  
  '@mobile': {
    width: '100%',
    padding: "0px",
    maxWidth: '850px',
    margin: '40px auto 0',
    marginBottom: '80px'
  }
});

export default FAQ;