import { useContext, useEffect, useState } from "react";
import { useLocation } from "wouter";
import { AuthContext } from "../utils/useAuth";
import { handleCreateRoom } from "~/api/roomApi";
import { keyframes, styled } from "~/ui/style/stitches.config";
import { useSnapshot } from "valtio";
import { localState } from "~/state/state";
import { BigButton, BookingBigButton } from "~/ui/components/BigButton";
import { motion } from "framer-motion";
import { FavesBar } from "~/ui/menus/FavesBar";
import { HelpButton } from "~/ui/components/HelpButton";
import { ProDirectory } from "./marketplace/ProDirectory";
import {
  DeckAnimation,
  DeckMarketplace,
  MobileOnlyBr,
} from "./marketplace/DeckMarketplace";
import { UserProfile } from "~/api/userApi";
import { Button } from "~/ui/components/Button";
import { HomeNavBar } from "~/ui/home/HomeNavBar";
import { ProChecklistSection } from "~/ui/home/ProChecklist";
import { ExpandingSection } from "~/ui/home/ExpandingSection";
import { ProfessionalDashboard } from "./marketplace/Availability";
import { Upcoming } from "./marketplace/Upcoming";
import { Marquee } from "~/ui/components/Marquee";
import allProPicsJson from "../preloading/allProPics.json";
import { ProPayoutsOverlay } from "~/ui/home/ProPayoutsOverlay";
import { Message } from "~/ui/components/Message";
import { CartOverlay } from "./marketplace/DeckOverlay";
import { imageUrl } from "~/utils/imageurl";
import { allDecks } from "~/ui/menus/DeckMenu";
import { Deck } from "~/types/room/types";
import {
  setVideoModalVisible,
  VideoModal,
} from "./marketplace/booking/BookingModule";
import { trackEvent } from "~/api/analyticsApi";
import { HOMEPAGE_PHASE, MoonlightHomepage } from "./MoonlightHomepage";
import { Referrals } from "./Referrals";

export enum MOONLIGHT_PAGE {
  HOME,
  DECKS,
  BOOK,
  REFERRALS,
  AVAILABILITY,
  UPCOMING,
  GCAL_TEST,
  CREATE_ROOM,
}

const Home = ({
  user,
  page,
  deckPageId,
}: {
  user: UserProfile | null;
  page: MOONLIGHT_PAGE;
  deckPageId?: string;
}) => {
  const { isConnecting, isOnMobile } = useSnapshot(localState);
  const [isTransitioningToRoom, setIsTransitioningToRoom] = useState(false);
  const [isTransitioningHomepageContent, setIsTransitioningHomepageContent] =
    useState(true);

  const [isFavesBarOpen, setIsFavesBarOpen] = useState(false);
  const [activePage, setActivePage] = useState<MOONLIGHT_PAGE>(
    MOONLIGHT_PAGE.HOME
  );
  const [isPayoutsOverlayOpen, setIsPayoutsOverlayOpen] = useState(false);
  const [shouldLongFadeIn, setShouldLongFadeIn] = useState(true);
  const [location, setLocation] = useLocation();
  const auth = useContext(AuthContext);
  const [twoRandomDecks, setTwoRandomDecks] = useState<Deck[]>([]);
  const [fourRandomProPics, setFourRandomProPics] = useState<string[]>([
    "",
    "",
    "",
    "",
  ]);
  const [shouldHoverDeck1, setShouldHoverDeck1] = useState(false);
  const [shouldHoverDeck2, setShouldHoverDeck2] = useState(false);
  const [currPhase, setCurrPhase] = useState<HOMEPAGE_PHASE>(
    HOMEPAGE_PHASE.THEME_SELECTION
  );

  

  useEffect(() => {
    trackEvent("/home/visited_page");


    // get four random pro pics
    const allProPics = allProPicsJson as string[];
    const shuffledProPics = allProPics.sort(() => 0.5 - Math.random());
    const selectedProPics = shuffledProPics.slice(0, 4);
    setFourRandomProPics(selectedProPics);

    // get two random decks
    const shuffledDecks = allDecks.sort(() => 0.5 - Math.random());
    const selectedDecks = shuffledDecks.slice(0, 2);
    setTwoRandomDecks(selectedDecks);
  }, []);

  useEffect(() => {
    if (isTransitioningToRoom && !isConnecting) {
      window.setTimeout(() => {
        // If user isn't logged in, redirect to signup
        if (!user) {
          const inviteCode = new URLSearchParams(window.location.search).get('invite');
          const inviteParam = inviteCode ? `&invite=${inviteCode}` : '';
          window.location.href = `/signup?dest=/create-room${inviteParam}`;
          return;
        }
        handleCreateRoom(user);
      }, 500);
    }
  }, [isTransitioningToRoom]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.page === "pros") {
      setLocation("/book");
    } else if (params.page === "decks") {
      if (deckPageId) setLocation("/decks/" + deckPageId);
      else setLocation("/decks");
    } else if (params.page === "availability") {
      setLocation("/availability");
    } else if (params.page === "sessions") {
      setLocation("/sessions");
    } else if (params.page === "invites") {
      setLocation("/invites");
    }
  }, [window.location.search]);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.getElementById("main-container")!.scrollTop = 0;

    setActivePage(page);

    if (page === MOONLIGHT_PAGE.CREATE_ROOM) {
      const urlParams = new URLSearchParams(window.location.search);
      const spreadId = urlParams.get("spread");
      const deckId = urlParams.get("deck");
      console.log(spreadId);
      if (auth.user) {
        document.getElementById("create-room-button")?.click();
      } else {
        const deckParam = deckId ? `?deck=${deckId}` : "";
        const spreadParam = spreadId
          ? deckParam.length > 0
            ? `&spread=${spreadId}`
            : `?spread=${spreadId}`
          : "";
        const allParams = `${deckParam}${spreadParam}`;
        window.location = ("/signup?dest=/create-room" +
          allParams) as unknown as Location;
      }
    }
  }, [page]);


  let trialCopy = "";

  if (user) {
    const now = new Date();
    const trialEnd = new Date(user.trialEndsAt);
    
    // Calculate time difference in milliseconds
    const timeDiff = trialEnd.getTime() - now.getTime();
    
    // Calculate days and hours
    const oneDay = 24 * 60 * 60 * 1000;
    const diffDays = Math.floor(timeDiff / oneDay);
    const diffHours = Math.floor(timeDiff / (60 * 60 * 1000));
    
    console.log('Trial ends at:', trialEnd);
    
    if (diffDays > 0 && diffDays <= 3) {
      // Between 1-3 days left
      trialCopy = diffDays === 1
        ? "1 day left in free trial"
        : `${diffDays} days left in free trial`;
    } else if (diffDays === 0 && diffHours >= 1) {
      // Less than a day but at least 1 hour left
      trialCopy = diffHours === 1
        ? "1 hour left in free trial"
        : `${diffHours} hours left in free trial`;
    }
    // If less than 1 hour left, trialCopy remains empty
  }


  return (
    <div
      id="main-container"
      style={{
        position: "relative",
        width: "calc(100vw)",
        height: "calc(100vh)",
        overflow: "auto",
      }}
      onScroll={(e) => {
        if (e.currentTarget.scrollTop > 100) {
          setShouldHoverDeck1(true);
        }
      }}
    >
      <VideoModal
        isHomepageModal={true}
        videoUrl="/images/pro-videos/moonlight_compilation.mp4"
        isBookable={false}
        secondButtonCopy={"Browse all pros"}
        titleCopy="Meet the pros"
        subtitleCopy="Find clarity with a professional guide or explore our tarot sandbox."
        secondCallback={() => {
          trackEvent("/home/popup/browse_all_pros");
          setLocation("/book");
        }}
      />
      <Message />
      <CartOverlay />

      <ProPayoutsOverlay
        isOpen={isPayoutsOverlayOpen}
        setIsOpen={setIsPayoutsOverlayOpen}
      />
      {activePage === MOONLIGHT_PAGE.BOOK && <ProDirectory />}
      {activePage === MOONLIGHT_PAGE.DECKS && (
        <DeckMarketplace deckPageId={deckPageId} />
      )}
      {activePage === MOONLIGHT_PAGE.AVAILABILITY && (
        <ProfessionalDashboard activePage={activePage} />
      )}
      {activePage === MOONLIGHT_PAGE.UPCOMING && <Upcoming />}
      {activePage === MOONLIGHT_PAGE.REFERRALS && <Referrals />}

      {activePage === MOONLIGHT_PAGE.HOME && (
        <StyledDashboard style={{ backgroundColor: "transparent" }}>
          <ProChecklistSection
            setIsPayoutsOverlayOpen={setIsPayoutsOverlayOpen}
            activePage={activePage}
          />

          <MoonlightHomepage
            isTransitioning={isTransitioningHomepageContent}
            setIsTransitioning={setIsTransitioningHomepageContent}
            currPhase={currPhase}
            setCurrPhase={setCurrPhase}
          />

          <div
            id={`homepage-sections`}
            className={`active-${
              currPhase === HOMEPAGE_PHASE.THEME_SELECTION &&
              !isTransitioningHomepageContent
            }`}
          >
            <div
              id="title-container"
              style={{
                display: "flex",
                // overflow: "hidden",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "-170px",
              }}
            >
              <div
                className="homepage-section"
                style={{ position: "relative", backgroundColor: "#EBEBEB" }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    opacity: 1,
                    clipPath: "inset(0 0 0 0)",
                    display: "inherit",
                    justifyContent: "inherit",
                    alignItems: "inherit",
                    flexDirection: "inherit",
                  }}
                >

                  <motion.div
                    key={"subtitle"}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, delay: 1 }}
                    className="subtitle"
                    style={{ marginTop: "0px", zIndex: 1 }}
                  >
                    Create your own tarot room.
                    {!user && <br />}
                    {!user && "Try it free for 7 days!"}
                    </motion.div>


                   {trialCopy && (<motion.div className="sub-subtitle"
                   style={{
                    fontSize:"10px",
                    color:"#a5a5a5",
                    textTransform:"uppercase"
                    }}>
                    {trialCopy}
                  </motion.div>
                  )
                  }

                  <motion.div className="sub-subtitle">
                    Pull cards for any moment or deepen your practice with
                    interactive decks. Read solo or invite friends in.
                  </motion.div>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      opacity: 1,
                      marginTop: "-10px",
                      paddingTop: "10px",
                      clipPath: "inset(0 0 0 0)",
                      display: "inherit",
                      justifyContent: "inherit",
                      alignItems: "inherit",
                      flexDirection: "inherit",
                    }}
                  >
                    <BigButton
                      cursorClasses={`transitioning-${isTransitioningToRoom}`}
                      onClick={() => {
                        trackEvent("/home/start_room");
                        setIsTransitioningToRoom(true);
                      }}
                      enabled={!isFavesBarOpen}
                      text={"CREATE A RITUAL"}
                    />

                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.5, delay: 1.5 }}
                      key="taglines"
                      className="taglines"
                    >
                      <div className="tagline">
                        <img src="/images/video.svg" />
                        <div className="text">video & audio included</div>
                      </div>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="homepage-section"
              style={{ zIndex: 1, backgroundColor: "#E9B9FF" }}
            >
              <motion.div
                key={"subtitle"}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 1 }}
                className="subtitle"
              >
                Book a professional session.
              </motion.div>
              <motion.div
                className="sub-subtitle"
                style={{ marginTop: "0px", zIndex: 1 }}
              >
                Because sometimes you need a fresh perspective. Book live or
                recorded readings with readers who get you.
              </motion.div>
              <div className="tarot-pics">
                <img
                  src={imageUrl(`/rotating-pros${fourRandomProPics[0]}`, 140)}
                  onClick={() => {
                    window.open("/book", "_blank");
                  }}
                />
                <img
                  src={imageUrl(`/rotating-pros${fourRandomProPics[1]}`, 140)}
                  onClick={() => {
                    window.open("/book", "_blank");
                  }}
                />
                <img
                  src={imageUrl(`/rotating-pros${fourRandomProPics[2]}`, 140)}
                  onClick={() => {
                    window.open("/book", "_blank");
                  }}
                />
                <img
                  src={imageUrl(`/rotating-pros${fourRandomProPics[3]}`, 140)}
                  onClick={() => {
                    window.open("/book", "_blank");
                  }}
                />
              </div>
              <Button
                onClick={() => {
                  trackEvent("/home/see_all_readers");
                  setLocation("/book");
                }}
                style={{
                  fontSize: "12px",
                  padding: "12px 0px",
                  width: "150px",
                  borderRadius: "30px",
                  marginTop: "30px",
                }}
                darkHover
              >
                See vetted readers
              </Button>
            </div>

            <div
              className="homepage-section"
              style={{
                position: "relative",
                backgroundColor: "#EBEBEB",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  opacity: 1,
                  clipPath: "inset(0 0 0 0)",
                  display: "inherit",
                  justifyContent: "inherit",
                  alignItems: "inherit",
                  flexDirection: "inherit",
                }}
              >
                <motion.div
                  key={"subtitle"}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 1 }}
                  className="subtitle"
                >
                  Explore digital decks that feel real.
                </motion.div>
                <motion.div
                  className="sub-subtitle"
                  style={{ marginTop: "0px", zIndex: 1 }}
                >
                  Smooth shuffling, indie artwork, and mini guidebooks.
                  Personalize readings that feel like magic, even on screen.
                </motion.div>
                {twoRandomDecks && twoRandomDecks.length > 1 && (
                  <div
                    className="deck-animations"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "70px",
                      width: "60%",
                    }}
                  >
                    <div
                      className="deck-animation-container"
                      style={{
                        transform: isOnMobile ? "scale(0.9)" : "scale(0.7)",
                        marginLeft: isOnMobile ? "60px" : "",
                        zIndex: 2,
                      }}
                      onPointerEnter={(e) => {
                        if (!isOnMobile) {
                          setShouldHoverDeck1(true);
                          setShouldHoverDeck2(false);
                        }
                      }}
                      onPointerLeave={(e) => {
                        if (!isOnMobile) {
                          setShouldHoverDeck1(false);
                        }
                      }}
                      onClick={() => {
                        if (!isOnMobile) {
                          setLocation("/decks");
                        } else {
                          if (shouldHoverDeck1) {
                            setShouldHoverDeck1(false);
                          } else {
                            setShouldHoverDeck1(true);
                          }
                        }
                      }}
                    >
                      <DeckAnimation
                        deck={twoRandomDecks[0]}
                        isHovered={shouldHoverDeck1}
                      />
                    </div>
                    {!isOnMobile && (
                      <div
                        className="deck-animation-container"
                        style={{ transform: "scale(0.7)" }}
                        onPointerEnter={(e) => {
                          setShouldHoverDeck2(true);
                          setShouldHoverDeck1(false);
                        }}
                        onPointerLeave={(e) => {
                          setShouldHoverDeck2(false);
                        }}
                        onClick={() => {
                          if (!isOnMobile) {
                            setLocation("/decks");
                          }
                        }}
                      >
                        <DeckAnimation
                          deck={twoRandomDecks[1]}
                          isHovered={shouldHoverDeck2}
                        />
                      </div>
                    )}
                  </div>
                )}
                <Button
                  onClick={() => {
                    trackEvent("/home/shop_decks");
                    setLocation("/decks");
                  }}
                  className="shop-decks-button"
                  style={{
                    fontSize: "12px",
                    padding: "12px 0px",
                    width: "150px",
                    borderRadius: "30px",
                  }}
                  darkHover
                >
                  Shop decks
                </Button>
              </div>
            </div>
            <div
              className="homepage-section"
              style={{
                zIndex: 1,
                backgroundColor: "#ECD8CE",
                marginBottom: "150px",
              }}
            >
              <motion.div
                key={"subtitle"}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 1 }}
                className="subtitle"
              >
                Join our professional community.{" "}
              </motion.div>
              <motion.div
                className="sub-subtitle"
                style={{ marginTop: "0px", zIndex: 1 }}
              >
                Elevate your tarot practice with a profile page, sleek booking &
                payments, and tools for hosting beautiful sessions. Apply to be
                in a future cohort.
              </motion.div>

              <Button
                onClick={() => {
                  window.open(
                    " https://forms.moonlight.world/professional-application",
                    "_blank"
                  );
                }}
                style={{
                  fontSize: "12px",
                  padding: "12px 0px",
                  width: "150px",
                  borderRadius: "30px",
                  marginTop: "16px",
                }}
                darkHover
              >
                Apply here{" "}
              </Button>
            </div>
          </div>

          {!isTransitioningToRoom && (
            <Marquee
              page={"home"}
              onClick={() => {
                trackEvent("/home/pressed_bottom_bar");
              }}
              text={
                "<a class='marquee-text' href='https://www.instagram.com/entermoonlight/' target='_blank'>welcome to moonlight ・ follow us @entermoonlight</a>"
              }
            />
          )}
        </StyledDashboard>
      )}
      <StyledHelpButton>
        <HelpButton />
      </StyledHelpButton>
      <HomeNavBar
        activePage={activePage}
        setActivePage={setActivePage}
        setShouldLongFadeIn={setShouldLongFadeIn}
        setIsPayoutsOverlayOpen={setIsPayoutsOverlayOpen}
      />
    </div>
  );
};

export default Home;

const fadeinNoDelay = keyframes({
  "0%": {
    opacity: 0,
    backgroundColor: "$wash",
  },

  "100%": {
    opacity: 1,
    backgroundColor: "$wash",
  },
});

const fadein = keyframes({
  "0%": {
    opacity: 0,
    backgroundColor: "$wash",
  },
  "25%": {
    opacity: 0,
    backgroundColor: "$wash",
  },
  "100%": {
    opacity: 1,
    backgroundColor: "$wash",
  },
});

export const StyledHelpButton = styled(motion.div, {
  "@mobile": {
    "& button": {
      transform: "translateY(-32px)",
    },
  },
});

const StyledDashboard = styled("div", {
  marginLeft: "calc($navWidth - 20px)",
  width: "calc(100% - $navWidth)",
  "@mobile": {
    marginLeft: "0px",
    width: "100%",
    "& #title-container": {
      width: "100%",
      transition: "opacity 0.5s",
    },
    marginBottom: "70px",
    overflow: "hidden",
  },

  "& #homepage-sections": {
    transition: "opacity 0.5s",
    "&.active-true": {
      opacity: 1,
    },
    "&.active-false": {
      opacity: 0,
      height: 0,
      overflow: "hidden",
      pointerEvents: "none",
    },
  },
  "& .homepage-section": {
    marginTop: "20px",
    display: "flex",
    backgroundColor: "white",
    flexDirection: "column",
    width: "600px",
    border: "0.5px solid rgba(0, 0, 0, 0.4)",
    borderRadius: "31px",
    boxShadow: "0px 3px 0px 0px rgba(0, 0, 0, 1)",
    padding: "50px 0px",
    "& .sub-subtitle": {
      fontSize: "12px",
      lineHeight: "1.4em",
      marginTop: "8px",
      marginBottom: "5px",
      textAlign: "center",
      maxWidth: "380px",
      padding: "0px 20px",
    },
    alignItems: "center",
    "@mobile": {
      width: "calc(100% - 32px)",
      marginLeft: "16px",
      marginRight: "16px",
    },
    "&.big-button-container": {
      flexDirection: "row",
      justifyContent: "space-around",
    },

    "& button": {
      "@mobile": {
        fontSize: "13px !important",
        padding: "18px 0px",
        width: "150px !important",
        height: "44px !important",
        "&.shop-decks-button": {
          marginTop: "8px",
        },
        "&.start-button": {
          width: "150px !important",
          fontSize: "14px !important",
        },
      },
    },
  },
  "& .big-button-section": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: "-100px",
  },

  "& .deck-animation-container": {
    "@mobile": {
      marignTop: "-50px",
      marginBottom: "30px",
    },
  },

  "& .tarot-pics": {
    marginTop: "30px",
    "& img": {
      width: "70px",
      height: "144px",
      objectFit: "cover",
      // center cover position of images
      borderRadius: "35px",
      border: "1px solid black",
      marginLeft: "2px",
      marginRight: "2px",
      cursor: "pointer",
    },
  },

  "& .help-button": {
    "@mobile": {
      "& button": {
        transform: "translateY(-56px)",
      },
      // transform: "translateY(-1px)",
    },
  },

  position: "relative",

  // height: "100vh",
  display: "flex",
  alignItems: "center",
  // justifyContent: "center",
  flexDirection: "column",
  backgroundColor: "$wash",
  "& .buttons": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "24px",
  },

  "& .button-container": {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "& .subtext": {
      fontSize: "11px",

      marginTop: "14px",
    },
  },

  "& .title, & .subtitle": {
    zIndex: "100",
    pointerEvents: "none",
  },

  "& .title": {
    fontFamily: "GorgeousBaby",
    fontSize: "40px",
    // marginTop: "-60px",
  },

  "& .subtitle": {
    fontFamily: "KeplerLightCondensed",
    fontSize: "24px",
    marginTop: "10px",
    textAlign: "center",
    "@mobile": {
      marginTop: "8px",
      fontSize: "26px",
    },
  },
  "& .taglines": {
    display: "flex",
    zIndex: "10",

    "& .tagline": {
      marginLeft: "12px",
      marginRight: "12px",
      marginTop: "-12px",
      display: "flex",
      fontSize: "12px",
      "@mobile": {
        fontSize: "13px",
      },
      alignSelf: "center",
      justifyContent: "center",
      alignItems: "center",
      "& img": {
        marginRight: "4px",
      },
    },
    "@mobile": {
      flexDirection: "column !important",
      // marginTop: "-60px",

      "& .tagline": {
        // marginTop: "8px",
      },
    },
  },

  variants: {
    dontFade: {
      true: {
        animation: `${fadeinNoDelay} 0.4s`,
      },
      false: {
        animation: `${fadein} 3s`,
      },
    },
  },
});
