import { useEffect, useState, useContext, MouseEventHandler } from "react";
import { useLocation } from "wouter";
import { keyframes, styled } from "~/ui/style/stitches.config";
import { AuthContext } from "~/utils/useAuth";
import { SocialBottomBar } from "./HomeNavBar";
import { PlusBadge } from "../menus/BackgroundMenu";
import { MOONLIGHT_PAGE } from "~/pages/Home";
import { imageUrl } from "~/utils/imageurl";
import { trackEvent } from "~/api/analyticsApi";

export const HomeSideBarFeature = ({
  title,
  onClick,
  gif,
  active,
  mobileOnly,
  lowerText,
}: {
  title: string;
  onClick: Function;
  gif: string;
  active: boolean;
  mobileOnly?: boolean;
  lowerText: string;
  closeMenu?: Function;
}) => {
  const diameter = 100;
  return (
    <StyledHomeSideBarFeature
      mobileOnly={mobileOnly ? true : false}
      active={active}
      onClick={() => onClick()}
    >
      <div>
        <div className="svg-container">
          <svg viewBox={`0 0 ${diameter} ${diameter}`} style={{}}>
            <path
              id="curve"
              fill="transparent"
              d="
            M 10, 50
            a 30,30 0 1,1 60,0
            a 30,30 0 1,1 -60,0
          "
            />
            <text className="text" width={diameter}>
              <textPath
                // x="50%"
                // y="50%"
                // text-anchor="middle"
                xlinkHref="#curve"
              >
                {title}
              </textPath>
            </text>
          </svg>
        </div>
        <img src={gif} className="feature-gif" />
        <div className="lower-text">{lowerText}</div>
      </div>
    </StyledHomeSideBarFeature>
  );
};

const rotate = keyframes({
  "0%": {
    transform: "scale(1.2) rotate(0deg) ",
  },
  "100%": {
    transform: "scale(1.2) rotate(360deg) ",
  },
});

const StyledHomeSideBarFeature = styled("div", {
  cursor: "pointer",
  // backgroundColor: "blue",
  // display: "flex",
  "&:hover": {
    "@notmobile": {
      "& .description": {
        display: "block",
      },
    },
  },

  "& .description": {
    display: "none",
    position: "absolute",
    top: "16px",

    left: "calc(100% + 20px)",
    backgroundColor: "white",
    border: "1px solid black",
    borderRadius: "15px",
    width: "120px",
    fontSize: "11px",
    padding: "10px 14px",
  },
  position: "relative",
  height: "84px",
  width: "92px",
  left: 0,
  right: 0,
  margin: "auto",
  borderRadius: "12px",
  marginBottom: "0px",
  marginTop: "4px",

  "& .svg-container": {},
  "& svg": {
    transform: "scale(1.2)",
    width: "80px",
    position: "absolute",
    top: "3px",
    left: "14px",
    right: "11px",
    margin: "auto",
    willChange: "transform",
    transformOrigin: "32px center",
    // animation: `${rotate} 10s linear infinite`,
  },

  "& .text, & .lower-text": {
    fontSize: "11px",
    letterSpacing: "0.3px",
    textTransform: "lowercase",
    // textAlign: "center",
    // width: "100%",
  },

  "& .lower-text": {
    position: "absolute",
    bottom: "8px",
    display: "none",
    fontSize: "12px",
    left: 0,
    right: 0,
    margin: "auto",
    textAlign: "center",
  },

  "@mobile": {
    "& .text": {
      display: "none",
    },
    "& .lower-text": {
      display: "block",
    },
    marginTop: "0px",
    marginBottom: "-4px",
  },
  "& img": {
    width: "50px",
    height: "50px",
    objectFit: "cover",
    borderRadius: "50%",
    position: "absolute",
    top: "18px",
    left: 0,
    right: 0,
    margin: "auto",
    transition: "all 0.2s ease-out",
    "@mobile": {
      top: "8px",
    },
  },
  "&:hover img": {
    // width: "66px",
    // borderRadius: "33px",
  },
  "&:hover svg": {
    animation: `${rotate} 10s linear infinite`,
    "@mobile": {
      animation: "none",
    },
  },

  variants: {
    active: {
      true: {
        // fontWeight: "500",
        backgroundColor: "$grayA50",
      },
      false: {
        "@notmobile": {
          "&:hover": {
            backgroundColor: "$grayA30",
          },
        },
      },
    },
    mobileOnly: {
      true: {
        "@notmobile": {
          display: "none",
        },
        "& img": {
          borderRadius: "0",
          width: "40px",
          height: "40px",
          paddingTop: "12px",
        },
      },
      false: {},
    },
  },
});

const MoreMenuContent = ({
  setActivePage,
  setIsPayoutsOverlayOpen,
}: {
  setActivePage: Function;
  setIsPayoutsOverlayOpen: Function;
}) => {
  const { user } = useContext(AuthContext);

  const [location, setLocation] = useLocation();

  enum UserView {
    LoggedOut = "logged-out",
    Basic = "basic",
    Plus = "plus",
    ProNotApprovedForServiceListings = "pro",
    ProApprovedForServiceListings = "pro-approved",
  }

  interface SmallMenu {
    title: string;
    img: string;
    view: UserView[];
    onClick: Function;
    isSelected?: boolean;
  }

  const smallMenus: SmallMenu[] = [
    {
      title: "about",
      img: "/room/link-out.svg",
      view: [UserView.LoggedOut],
      onClick: () => {
        // open about page in a new tab
        window.open("https://moonlight.world", "_blank");
      },
    },

    {
      title: "profile",
      img: "/more-menu/profile.svg",
      view: [
        UserView.Basic,
        UserView.Plus,
        UserView.ProNotApprovedForServiceListings,
        UserView.ProApprovedForServiceListings,
      ],
      onClick: () => {
        trackEvent("/nav/profile");
        // open user's profile in a new tab
        window.open(`/profile/${user!.username}`, "_blank");
      },
    },
    {
      title: "invites",
      img: "/more-menu/invite.svg",
      view: [
        UserView.Basic,
        UserView.Plus,
        UserView.ProApprovedForServiceListings,
        UserView.ProNotApprovedForServiceListings,
      ],
      isSelected: location === "/invites",
      onClick: () => {
        setLocation("/invites");
        setActivePage(MOONLIGHT_PAGE.REFERRALS);
      },
    },
    {
      title: "availability",
      img: "/more-menu/availability.svg",
      view: [UserView.ProApprovedForServiceListings],
      isSelected: location === "/availability",
      onClick: () => {
        setLocation("/availability");
        setActivePage(MOONLIGHT_PAGE.AVAILABILITY);
      },
    },

    {
      title: "sessions",
      img: "/more-menu/upcoming.svg",
      view: [
        UserView.Basic,
        UserView.Plus,
        UserView.ProApprovedForServiceListings,
        UserView.ProNotApprovedForServiceListings,
      ],
      isSelected: location === "/sessions",
      onClick: () => {
        setLocation("/sessions");
        setActivePage(MOONLIGHT_PAGE.UPCOMING);
      },
    },

    {
      title: "payouts",
      img: "/more-menu/payouts.svg",
      view: [UserView.ProApprovedForServiceListings],
      onClick: () => {
        setIsPayoutsOverlayOpen(true);
      },
    },
    {
      title: "blog",
      img: "/more-menu/blog.svg",
      view: [
        UserView.LoggedOut,
        UserView.Basic,
        UserView.Plus,
        UserView.ProNotApprovedForServiceListings,
      ],
      onClick: () => {
        // open about page in a new tab
        window.open("https://blog.moonlight.world", "_blank");
      },
    },
    
    {
      title: "support",
      img: "/more-menu/support.svg",
      view: [
        UserView.Basic,
        UserView.Plus,
        UserView.ProApprovedForServiceListings,
        UserView.ProNotApprovedForServiceListings,
      ],
      onClick: () => {
        document.getElementById("support")!.click();
      },
    },
  ];

  return (
    <>
      {user && (
        <div className="profile-container">
          <div className="name">{user?.name}</div>
          {(user?.isPlus || user?.isProfessional) && (
            <PlusBadge
              clickable
              isProBadge
              style={{ width: "72px", fontSize: "10px" }}
            >
              {user.isProfessional ? "PRO PLAN" : "SUBSCRIBED"}
            </PlusBadge>
          )}
        </div>
      )}
      <div className="small-menu-container">
        {smallMenus.map((menu, idx) => {
          // if logged out, only show logged out menus
          if (!user) {
            if (!menu.view.includes(UserView.LoggedOut)) return;
          }
          // if pro without services, only show non-service-pro pro menus
          else if (
            user &&
            user.isProfessional &&
            !user.proDetails?.approvedForServiceListing
          ) {
            if (!menu.view.includes(UserView.ProNotApprovedForServiceListings))
              return;
          }

          // if pro with services, only show non-service-pro pro menus
          else if (
            user &&
            user.isProfessional &&
            user.proDetails?.approvedForServiceListing
          ) {
            if (!menu.view.includes(UserView.ProApprovedForServiceListings))
              return;
          }
          // if basic, only show basic menus
          else if (user && !user.isPlus && !user.isProfessional) {
            if (!menu.view.includes(UserView.Basic)) return;
          }
          // if plus, only show plus menus
          else if (user && user.isPlus) {
            if (!menu.view.includes(UserView.Plus)) return;
          }

          return (
            <div
              key={"small-menu" + idx}
              className={`small-menu-item ${menu.isSelected ? "active" : ""}`}
              onClick={menu.onClick as MouseEventHandler<HTMLDivElement>}
            >
              <img
                src={
                  menu.img
                    ? `/images/${menu.img}`
                    : imageUrl(`/images/${menu.title}-icon.png`)
                }
              />
              {menu.title}
            </div>
          );
        })}
        <div
          className="mobile-only-bottom-section"
          style={{
            // marginTop: "12px",
            width: "100%",
            paddingBottom: "20px",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "rgba(0,0,0,0.2)",
              marginBottom: "40px",
            }}
          />
          <SocialBottomBar
            style={{ marginBottom: "12px", zIndex: "0", height: "30px" }}
          />
        </div>
      </div>
    </>
  );
};

export const HomeSideBar = ({
  activePage,
  setActivePage,
  setShouldLongFadeIn,
  onBillingPage,
  setIsPayoutsOverlayOpen,
}: {
  activePage: MOONLIGHT_PAGE;
  setActivePage: Function;
  setShouldLongFadeIn: Function;
  onBillingPage?: boolean;
  setIsPayoutsOverlayOpen: Function;
}) => {
  const [location, setLocation] = useLocation();
  const [inMoreMenu, setInMoreMenu] = useState(false);

  useEffect(() => {
    setInMoreMenu(false);
  }, [activePage]);

  return (
    <StyledHomeSideBar inMoreMenu={inMoreMenu}>
      <div className="inner-sidebar">
        <div className="more-menu" onClick={() => setInMoreMenu(false)}>
          <div className="inner-menu" onClick={(e) => e.stopPropagation()}>
            <img
              className="x-icon"
              onClick={() => setInMoreMenu(false)}
              src={"images/more-menu/exit.svg"}
            />
            <MoreMenuContent
              setActivePage={setActivePage}
              setIsPayoutsOverlayOpen={setIsPayoutsOverlayOpen}
            />
          </div>
        </div>
        <div
          className="feature-buttons-container  bar-section"
          style={{ marginTop: "4px" }}
        >
          <HomeSideBarFeature
            title="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Home"
            lowerText="Home"
            active={activePage === MOONLIGHT_PAGE.HOME}
            onClick={() => {
              trackEvent("/nav/home");
              setActivePage(MOONLIGHT_PAGE.HOME);
              setLocation("/");
              setShouldLongFadeIn(false);
            }}
            gif={imageUrl("/home-moon.png", 200)}
          />

          <HomeSideBarFeature
            title="&nbsp;&nbsp;&nbsp;&nbsp;Shop decks"
            lowerText="decks"
            active={activePage === MOONLIGHT_PAGE.DECKS}
            onClick={() => {
              trackEvent("/nav/decks");
              setLocation("/decks");
              setActivePage(MOONLIGHT_PAGE.DECKS);
            }}
            gif={imageUrl("/shop-decks.png", 200)}
          />
          <HomeSideBarFeature
            title="&nbsp;&nbsp;&nbsp;Book session"
            lowerText="book session"
            active={activePage === MOONLIGHT_PAGE.BOOK}
            onClick={() => {
              trackEvent("/nav/book");
              setLocation("/book");
              setActivePage(MOONLIGHT_PAGE.BOOK);
            }}
            gif={imageUrl("/book-session.gif", 200)}
          />
          <HomeSideBarFeature
            mobileOnly
            title="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;More"
            lowerText="More"
            active={
              activePage !== MOONLIGHT_PAGE.HOME &&
              activePage !== MOONLIGHT_PAGE.BOOK &&
              activePage !== MOONLIGHT_PAGE.DECKS
            }
            onClick={() => {
              setInMoreMenu(true);
            }}
            closeMenu={() => {
              setInMoreMenu(false);
            }}
            gif="/images/more-menu/menu.svg"
          />
        </div>
        <div className="bottom-section bar-section">
          <MoreMenuContent
            setActivePage={setActivePage}
            setIsPayoutsOverlayOpen={setIsPayoutsOverlayOpen}
          />
        </div>
      </div>
    </StyledHomeSideBar>
  );
};

const StyledHomeSideBar = styled("div", {
  width: "$navWidth",
  // backgroundColor: "white",
  height: "100%",
  position: "fixed",
  left: 0,
  top: 0,
  zIndex: 9,

  // backgroundColor: "$wash",

  "& .bar-section": {
    backgroundColor: "white",
    border: "1px solid rgba(0, 0, 0, 0.3)",
    borderRadius: "20px",
  },

  "& .feature-buttons-container": {
    padding: "8px 6px",
  },

  "& .inner-sidebar": {
    position: "fixed",
    top: "calc($navHeight + 19px)",
    height: "calc(100% - 136px)",
    width: "calc($navWidth - 32px)",
    display: "flex",
    flexDirection: "column",
    marginLeft: "16px",
  },
  "& .bottom-section": {
    marginBottom: "0px",
    marginTop: "16px",
    padding: "19px 2px 6px 0",
    "& .profile-container": {
      paddingLeft: "15px",
      paddingRight: "8px",
      paddingBottom: "6px",
      "& .profile-pic": {
        display: "none",
      },
      // borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
      "& .name": {
        fontSize: "11px",
        fontWeight: "500",
        paddingBottom: "4px",
      },
    },
  },

  "@mobile": {
    backgroundColor: "transparent",
    height: "auto",
    bottom: 0,
    top: "unset",
    "& .bottom-section": {
      display: "none",
    },
    "& .inner-sidebar": {
      height: "auto",
      backgroundColor: "white",
      borderTop: "1px solid black",
      width: "calc(100%)",
      bottom: "0",
      left: 0,
      right: 0,
      margin: "auto",
      top: "unset",
      zIndex: 99,
      "& .feature-buttons-container": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "8px 6px",
        width: "auto",
        backgroundColor: "transparent",
        border: "none",
        paddingTop: 0,

        // boxShadow: "0px 3px 0px 0px #000",
      },
    },
  },

  "& .small-menu-container": {
    display: "flex",
    flexDirection: "column",
    paddingTop: "4px",

    "@notmobile": {
      "& .mobile-only-bottom-section": {
        display: "none",
      },
    },

    "& .small-menu-item": {
      "&:hover": {
        backgroundColor: "$gray100",
        cursor: "pointer",
        "@mobile": {
          backgroundColor: "transparent",
        },
      },

      "&.active": {
        backgroundColor: "$gray100",
        "@mobile": {
          width: "calc(100% - 40px)",
        },
      },
      width: "calc($navWidth - 46px)",
      marginLeft: "6px",
      display: "flex",
      marginBottom: "6px",
      paddingTop: "4px",
      paddingBottom: "4px",
      paddingLeft: "4px",
      paddingRight: "4px",
      fontSize: "12px",
      borderRadius: "8px",
      textTransform: "capitalize",
      alignItems: "center",
      "& img": {
        width: "20px",
        height: "20px",
        marginRight: "6px",
      },
    },
  },

  "& .more-menu": {
    width: "100vw",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 999999,
    backgroundColor: "rgba(228, 228, 228, 0.8)",
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& .inner-menu": {
      backgroundColor: "white",
      borderRadius: "26px",
      paddingTop: "40px",
      paddingBottom: "10px",
      width: "260px",
      height: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      border: "1px solid black",
      boxShadow: "0px 3px 0px 0px #000",
      position: "relative",
      "& .x-icon": {
        width: "30px",
        position: "absolute",
        top: "16px",
        right: "16px",
      },
      "& .profile-container": {
        marginBottom: "32px",
        marginLeft: "24px",
        "& .profile-pic": {
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          marginBottom: "1px",
          marginTop: "12px",
        },
        "& .name": {
          marginBottom: "8px",
        },
      },
      "& .separator": {
        marginBottom: "18px !important",
      },
      "& .small-menu-item": {
        marginBottom: "24px",
        "@mobile": {
          marginBottom: "12px",
          paddingTop: "8px",
          paddingBottom: "8px",
        },
        marginLeft: "18px",

        fontSize: "14px",
        "& img": {
          marginRight: "12px",
        },
      },
    },
  },
  variants: {
    inMoreMenu: {
      true: {
        "@mobile": {
          "& .more-menu": {},
        },
      },
      false: {
        "& .more-menu": {
          display: "none",
        },
      },
    },
  },
});
