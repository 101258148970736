import { sendAnalyticsEvent } from "../utils/analytics.js";
import axios from "./axiosConfig.js";

export interface UserProfile {
  username : string
  uuid: string
  name : string
  email : string
  bio: string
  profilePic: string
  favorites: string[]
  location: string
  tagline: string
  itemPic: string
  animalPic: string
  pronouns: string
  languages: string
  marqueeText: string
  twitter: string
  instagram: string
  facebook: string
  tiktok: string
  youtube: string
  twitch: string
  website: string
  recommendedLinks: string[]
  moreInfo: string[]
  backgroundColor: string
  accentColor: string
  isProfessional: boolean
  isPlus: boolean
  isMuted: boolean
  isCameraOff: boolean
  decks: string[]
  tags?: string[]
  calId: string
  acceptedTerms: string
  selectedGoogleId: string
  gcalIsAuthorized: boolean
  timeZone: string
  bookingHeader: string
  proDetails?: ProDetails
  acceptingPayments?: boolean
  stripeRequiredActions?: string[]
  createdAt: string
  trialEndsAt: string
}

interface ProDetails {
  id: string
  userId: string
  stripeConnectId: string | null
  approvedForServiceListing: boolean
  hasVideo: boolean | null
}

const USER_ENDPOINT = "users/"

// This should only be called from within the auth context flow
const getCurrentUser = async () : Promise<UserProfile> => {
  const response = await axios.get(USER_ENDPOINT)
  if (response.status === 200) {
    return response.data as UserProfile
  } else {
    throw new Error(response.statusText)
  }
}

const getProUsers = async() : Promise<UserProfile[]> => {
  const response = await axios.get(USER_ENDPOINT + "pros/")
  return (response.data as UserProfile[]).sort(() => Math.random() - 0.5);
}

const getUser = async (uuid : string) : Promise<UserProfile> => {
  const response = await axios.get(USER_ENDPOINT + "id/" + uuid)
  if (response.status === 200) {
    return response.data as UserProfile
  } else {
    throw new Error(response.statusText)
  }
}

const getUserByUsername = async (username : string) : Promise<UserProfile> => {
  const response = await axios.get(USER_ENDPOINT + username)
  if (response.status === 200) {
    return response.data as UserProfile
  } else {
    throw new Error(response.statusText)
  }
}

// Note: this update should only be called via the auth flow.
const updateUser = async (updateUserData: Partial<UserProfile>): Promise<UserProfile> => {
  const response = await axios.put(USER_ENDPOINT, updateUserData)
  if (response.status === 200) {
    sendAnalyticsEvent('edited_profile');
  }
  if (response.status !== 200) {
    throw new Error(response.statusText)
  }
  return response.data
}

// Note: this update should only be called via the auth flow.
const processDeckPayment = async (deckIds: string[], paymentIntentId: string, paymentGroupId: string): Promise<UserProfile> => {
  const response = await axios.post(USER_ENDPOINT + "decks", {
    deckIds,
    paymentIntentId,
    paymentGroupId
  })
  if (response.status !== 200) {
    throw new Error(response.statusText)
  }
  return response.data
}

// Users have full access if they are:
// 1. A professional reader
// 2. A plus subscriber (active Stripe plan)
// 3. In an active trial (< 90 days)
// 4. In an extended trial (> 90 days)

const hasFullAccess = (user: UserProfile | null): boolean => {
  if (!user) return false;
  if (user.isProfessional || user.isPlus) return true;
  return new Date(user.trialEndsAt) > new Date();
}

const isInTrial = (user: UserProfile): boolean => {
  if (!user) return false;
  return new Date(user.trialEndsAt) > new Date();
}

const isInExtendedTrial = (user: UserProfile): boolean => {
  if (!user) return false;
  const trialEnd = new Date(user.trialEndsAt);
  const now = new Date();
  if (trialEnd <= now) return false;
  const trialLength = (trialEnd.getTime() - now.getTime()) / (1000 * 60 * 60 * 24);
  return trialLength > 90;
}

export { 
  getProUsers, 
  getCurrentUser, 
  getUser, 
  getUserByUsername, 
  hasFullAccess,  // replacing isPremium
  isInTrial,
  isInExtendedTrial,
  updateUser, 
  processDeckPayment 
}