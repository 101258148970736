import { set } from "date-fns";
import { is } from "date-fns/locale";
import React, { useContext, useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { SigninForm } from "~/ui/components/auth/Signin";
import { Button } from "~/ui/components/Button";
import { keyframes, styled } from "~/ui/style/stitches.config";
import { AuthContext } from "~/utils/useAuth";
import { AuthFormState, AuthPage } from "./login/Auth";
import { createNewQuestion } from "~/api/questionsApi";
import { useSnapshot } from "valtio";
import { localState } from "~/state/state";
import { trackEvent } from "~/api/analyticsApi";
import { SenjaWidget } from "./marketplace/ProDirectory";
import { UserProfile, hasFullAccess, isInTrial, isInExtendedTrial } from "~/api/userApi";


export const ParticleBlob: React.FC = () => {
  const mountRef = useRef<HTMLDivElement | null>(null);
  let mouse = new THREE.Vector2();
  let prevMouse = new THREE.Vector2(); // Previous mouse position
  const blobRadius = 2; // Blob size
  const movementThreshold = 0.001; // Minimum mouse movement to trigger interaction
  let time = 0; // Time variable for fluid motion

  useEffect(() => {
    const mount = mountRef.current!;

    // Create scene, camera, and renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    camera.position.z = 5;

    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true }); // Transparent background
    renderer.setSize(window.innerWidth, window.innerHeight);
    mount.appendChild(renderer.domElement);

    // Particle setup
    const particleCount = 5000;
    const geometry = new THREE.BufferGeometry();
    const positions = new Float32Array(particleCount * 3);
    const originalPositions = new Float32Array(particleCount * 3); // Store original positions

    for (let i = 0; i < particleCount; i++) {
      const radius = Math.random() * blobRadius;
      const angle = Math.random() * Math.PI * 2;

      const x = radius * Math.cos(angle);
      const y = radius * Math.sin(angle);
      const z = (Math.random() - 0.5) * 0.1; // Keep it flatter for smoother blending

      positions[i * 3] = x;
      positions[i * 3 + 1] = y;
      positions[i * 3 + 2] = z;

      originalPositions[i * 3] = x;
      originalPositions[i * 3 + 1] = y;
      originalPositions[i * 3 + 2] = z;
    }

    geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));

    // Material with soft blending and smooth color transition
    const material = new THREE.ShaderMaterial({
      uniforms: {
        uCenterColor: { value: new THREE.Color(0xd28fff) }, // Purple center
        uEdgeColor: { value: new THREE.Color(0xffddc1) }, // Peachy edge
        uTime: { value: 0.0 }, // Time for motion
      },
      vertexShader: `
        uniform float uTime;
        varying vec3 vPosition;
        void main() {
          vPosition = position;
          
          // Apply a subtle fluid motion over time
          vec3 fluidMovement = vec3(
            sin(position.x * 2.0 + uTime) * 0.01,
            cos(position.y * 2.0 + uTime) * 0.01,
            sin(position.z * 2.0 + uTime) * 0.01
          );
          
          vec3 newPosition = position + fluidMovement;  // Fluid-like motion
          gl_PointSize = 2.0;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(newPosition, 1.0);
        }
      `,
      fragmentShader: `
        uniform vec3 uCenterColor;
        uniform vec3 uEdgeColor;
        varying vec3 vPosition;

        void main() {
          // Smooth gradient between center and edge colors
          float dist = length(vPosition);
          vec3 color = mix(uCenterColor, uEdgeColor, dist / 1.2);
          gl_FragColor = vec4(color, 0.5);  // Soft transparency

          // Make particles round
          if (length(gl_PointCoord - vec2(0.5)) > 0.4) discard;
        }
      `,
      transparent: true,
      depthTest: false,
      blending: THREE.NormalBlending, // Preserve color accuracy
    });

    const particles = new THREE.Points(geometry, material);
    scene.add(particles);

    // Fix mouse movement issue
    const handleMouseMove = (event: MouseEvent) => {
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    };

    // Attach the event listener for mouse move
    window.addEventListener("mousemove", handleMouseMove);

    // Animation loop with fluid motion
    const animate = () => {
      requestAnimationFrame(animate);
      time += 0.01;
      material.uniforms.uTime.value = time; // Update time uniform

      const positionsArray = geometry.attributes.position.array as Float32Array;

      for (let i = 0; i < particleCount; i++) {
        const dx = mouse.x * 2 - positionsArray[i * 3];
        const dy = mouse.y * 2 - positionsArray[i * 3 + 1];
        const distance = Math.sqrt(dx * dx + dy * dy);

        // Move particles based on proximity to mouse
        if (distance < 0.5) {
          positionsArray[i * 3] += (positionsArray[i * 3] - mouse.x) * 0.05;
          positionsArray[i * 3 + 1] +=
            (positionsArray[i * 3 + 1] - mouse.y) * 0.05;
        } else {
          // Return particles to original position smoothly
          positionsArray[i * 3] +=
            (originalPositions[i * 3] - positionsArray[i * 3]) * 0.02;
          positionsArray[i * 3 + 1] +=
            (originalPositions[i * 3 + 1] - positionsArray[i * 3 + 1]) * 0.02;
        }
      }

      geometry.attributes.position.needsUpdate = true; // Update positions
      renderer.render(scene, camera); // Render scene
    };

    animate();

    // Cleanup
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      mount.removeChild(renderer.domElement);
    };
  }, []);

  return <div ref={mountRef} />;
};

const vertexShader = `
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = vec4(position, 1.0);
  }
`;

const fragmentShader = `
  uniform vec2 u_resolution;
  uniform vec2 u_mouse;
  uniform float u_time;
  
  varying vec2 vUv;
  
  // Simplex 2D noise
  vec3 permute(vec3 x) { return mod(((x*34.0)+1.0)*x, 289.0); }
  float snoise(vec2 v){
    const vec4 C = vec4(0.211324865405187, 0.366025403784439,
             -0.577350269189626, 0.024390243902439);
    vec2 i  = floor(v + dot(v, C.yy) );
    vec2 x0 = v -   i + dot(i, C.xx);
    vec2 i1;
    i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
    vec4 x12 = x0.xyxy + C.xxzz;
    x12.xy -= i1;
    i = mod(i, 289.0);
    vec3 p = permute( permute( i.y + vec3(0.0, i1.y, 1.0 ))
    + i.x + vec3(0.0, i1.x, 1.0 ));
    vec3 m = max(0.5 - vec3(dot(x0,x0), dot(x12.xy,x12.xy),
      dot(x12.zw,x12.zw)), 0.0);
    m = m*m ;
    m = m*m ;
    vec3 x = 2.0 * fract(p * C.www) - 1.0;
    vec3 h = abs(x) - 0.5;
    vec3 ox = floor(x + 0.5);
    vec3 a0 = x - ox;
    m *= 1.79284291400159 - 0.85373472095314 * ( a0*a0 + h*h );
    vec3 g;
    g.x  = a0.x  * x0.x  + h.x  * x0.y;
    g.yz = a0.yz * x12.xz + h.yz * x12.yw;
    return 130.0 * dot(m, g);
  }
  
  void main() {
    vec2 st = (gl_FragCoord.xy - u_resolution * 0.5) / 500.0 + 0.5;
    vec2 center = vec2(0.5, 0.5);
    
    // Enhance shimmering effect
    float shimmer = snoise(st * 2.0 + u_time * 0.3) * 0.04;
    
    // Enhance mouse influence
    vec2 mousePos = (u_mouse - u_resolution * 0.5) / 500.0 + 0.5;
    vec2 mouseVector = st - mousePos;
    float mouseDist = length(mouseVector);
    float mouseInfluence = exp(-mouseDist * 2.0) * 0.2;
    
    // Combine shimmering and mouse influence
    vec2 distortedSt = st + shimmer * vec2(cos(u_time), sin(u_time)) + mouseVector * mouseInfluence;
    
    // Calculate distance from center for gradient
    float dist = distance(distortedSt, center);
    
    // Create circular mask with soft edges
    float radius = 0.4;
    float circle = smoothstep(radius, radius - 0.4, dist);
    
    // Create gradient colors
    vec3 purpleColor = vec3(0.9137, 0.7255, 1.0);
    vec3 peachColor = vec3(0.98, 0.91, 0.87);
    vec3 color = mix(purpleColor, peachColor, smoothstep(0.0, radius, dist));
    
    // Apply circular mask
    gl_FragColor = vec4(purpleColor, circle);
  }
`;

const Gradient: React.FC = () => {
  const mountRef = useRef<HTMLDivElement>(null);
  const sceneRef = useRef<THREE.Scene | null>(null);
  const cameraRef = useRef<THREE.OrthographicCamera | null>(null);
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const materialRef = useRef<THREE.ShaderMaterial | null>(null);
  const frameIdRef = useRef<number | null>(null);

  let lastMouse = new THREE.Vector2();
  let velocity = new THREE.Vector2();

  useEffect(() => {
    if (!mountRef.current) return;

    const width = 500;
    const height = 500;

    // Scene setup
    sceneRef.current = new THREE.Scene();
    cameraRef.current = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);
    rendererRef.current = new THREE.WebGLRenderer({ alpha: true });
    rendererRef.current.setSize(width, height);
    mountRef.current.appendChild(rendererRef.current.domElement);

    const geometry = new THREE.PlaneGeometry(2, 2);
    materialRef.current = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms: {
        u_time: { value: 0 },
        u_resolution: { value: new THREE.Vector2(width, height) },
        u_mouse: { value: new THREE.Vector2(width / 2, height / 2) },
      },
      transparent: true,
    });

    const mesh = new THREE.Mesh(geometry, materialRef.current);
    sceneRef.current.add(mesh);

    // Animation loop
    const animate = (time: number) => {
      if (
        materialRef.current &&
        rendererRef.current &&
        sceneRef.current &&
        cameraRef.current
      ) {
        materialRef.current.uniforms.u_time.value = time / 1000;
        rendererRef.current.render(sceneRef.current, cameraRef.current);
      }
      frameIdRef.current = requestAnimationFrame(animate);
    };

    frameIdRef.current = requestAnimationFrame(animate);

    // Event listeners
    const handleMouseMove = (event: MouseEvent) => {
      document.getElementById("cursor-dot")!.style.left = event.clientX + "px";
      // document.getElementById("cursor-dot")!.style.left = e.clientX + "px";
      document.getElementById("cursor-dot")!.style.top = event.clientY + "px";

      if (
        materialRef.current &&
        mountRef.current &&
        materialRef.current.uniforms &&
        materialRef.current.uniforms.u_mouse
      ) {
        const rect = mountRef.current.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        const mousePos = new THREE.Vector2(x, height - y);

        // Calculate velocity (difference between frames)
        velocity.subVectors(mousePos, lastMouse);

        // Update the uniforms
        materialRef.current.uniforms.u_mouse.value.set(x, height - y);

        lastMouse.copy(mousePos); // Update the last mouse position
      }
    };

    window.addEventListener("mousemove", handleMouseMove);

    // Cleanup
    return () => {
      if (frameIdRef.current) cancelAnimationFrame(frameIdRef.current);
      window.removeEventListener("mousemove", handleMouseMove);
      if (mountRef.current && rendererRef.current) {
        mountRef.current.removeChild(rendererRef.current.domElement);
      }
    };
  }, []);

  return (
    <div
      ref={mountRef}
      style={{
        width: "500px",
        height: "500px",
        position: "absolute",
        left: "50%",
        top: "-180px",
        transform: "translate(-50%, 0%) scale(1.0)",
        zIndex: -1,
      }}
    />
  );
};

const themes = {
  work: {
    questions: [
      "What's next in my career?",
      "What wants to come alive in my work?",
      "What should I know about this opportunity?",
      "What is emerging with this launch?",
    ],
    title: "Work stuff. We get it!",
  },
  relationships: {
    questions: [
      "What's the energy between us right now?",
      "How can I show up differently in dating?",
      "What's underneath this current challenge?",
      "What's ready to shift in our relationship?",
    ],
    title: "Ooo. Relationships.",
  },
  "life changes": {
    questions: [
      "What’s next for my work, relationships, and creativity?",
      "Should I stay or should I go?",
      "What wisdom do I need for this decision?",
      "What's holding me back?",
    ],
    title: "Ooo. A plot twist!",
  },
  creativity: {
    questions: [
      "What's blocking my creative flow?",
      "What story is trying to be told?",
      "What's missing from this project?",
      "What's the energy of this collaboration?",
    ],
    title: "Let's figure it out!",
  },
  "inner magic": {
    questions: [
      "What's blocking my intuitive flow?",
      "How does my magic want to play?",
      "What needs to be released?",
      "What curiosities are calling me?",
    ],
    title: "Let's get deep.",
  },
  idk: {
    questions: [
      "What do I need to know about this next month?",
      "What's up with this weird energy?",
      "What bigger picture can I focus on?",
      "Should I live in New York?",
    ],
    title: "Let's explore together",
  },
};

export enum HOMEPAGE_PHASE {
  THEME_SELECTION,
  QUESTION_SELECTION,
  READING_SELECTION,
  SIGN_IN,
}

export const MoonlightHomepage = ({
  isTransitioning,
  setIsTransitioning,
  currPhase,
  setCurrPhase,
}: {
  isTransitioning: boolean;
  setIsTransitioning: Function;
  currPhase: HOMEPAGE_PHASE;
  setCurrPhase: Function;
}) => {
  const [currTheme, setCurrTheme] = useState<keyof typeof themes>(
    Object.keys(themes)[0] as keyof typeof themes
  );
  const [currTitle, setCurrTitle] = useState("");
  const [currQuestion, setCurrQuestion] = useState("");
  const [currSubtitle, setCurrSubtitle] = useState("");
  const [areQuestionsVisible, setAreQuestionsVisible] = useState(false);

  const [currContent, setCurrContent] = useState<React.ReactNode>(<div></div>);
  const [currSessionInfo, setCurrSessionInfo] = useState<any>({});
  const { user } = useContext(AuthContext);
  const { isConnecting } = useSnapshot(localState);
  const themeSelectionContent = (
    <>
      <div className="theme-select">
        <div className="theme-button-container">
          {Object.keys(themes).map((theme) => {
            return (
              <Button
                key={theme}
                onClick={() => {
                  trackEvent("/home/choose_theme", {
                    theme,
                  });
                  setCurrTheme(theme as keyof typeof themes);
                  setCurrPhase(HOMEPAGE_PHASE.QUESTION_SELECTION);
                }}
              >
                {theme}
              </Button>
            );
          })}
        </div>
        <div
          className="skip-button"
          onClick={() => {
            trackEvent("/home/skip_theme");
            setCurrPhase(HOMEPAGE_PHASE.READING_SELECTION);
          }}
        >
          Skip theme
        </div>
      </div>
    </>
  );
  const [currText, setCurrText] = useState("");

  const questionSelectionContent = (
    <div className="question-select">
      {currText.length >= 100 && (
        <div className="character-counter">{currText.length}/300</div>
      )}
      <input
        id="question-input"
        autoFocus
        maxLength={300}
        className={`selected-${currQuestion === ""}`}
        type={"text"}
        onFocus={() => {
          setAreQuestionsVisible(true);
          setCurrQuestion("");
        }}
        onChange={(e) => {
          setCurrQuestion(e.target.value);
          setCurrText(e.target.value);
        }}
        placeholder="Type your question or scenario..."
      />
      <div
        className={`question-option-container visible-${areQuestionsVisible}`}
      >
        {themes[currTheme].questions.map((question) => (
          <div
            className={`question-option active-${currQuestion === question}`}
            key={question}
            onClick={() => {
              setCurrQuestion(question);
              trackEvent("/home/submit_question", {
                theme: currTheme,
                question: question,
              });
              setCurrPhase(HOMEPAGE_PHASE.READING_SELECTION);
            }}
          >
            {question}
          </div>
        ))}
      </div>
      <div className="question-button-container">
        <div
          className="back-button"
          onClick={() => {
            window.setTimeout(() => {
              setCurrQuestion("");
            }, 300);

            setCurrPhase(HOMEPAGE_PHASE.THEME_SELECTION);
          }}
        >
          <img style={{ width: "50%" }} src="images/back-nav-icon.svg" />
        </div>
        <div
          className="skip-button"
          onClick={() => {
            trackEvent("/home/skip_question");
            setCurrQuestion("");
            setCurrTheme("idk");
            setCurrPhase(HOMEPAGE_PHASE.READING_SELECTION);
          }}
        >
          Skip question
        </div>
        <div
          className={`next-button visible-${
            currText !== "" || currQuestion !== ""
          }`}
          onClick={() => {
            trackEvent("/home/submit_question", {
              theme: currTheme,
              question: currQuestion,
            });
            setCurrPhase(HOMEPAGE_PHASE.READING_SELECTION);
          }}
        >
          <img style={{ width: "50%" }} src="images/back-nav-icon.svg" />
        </div>
      </div>
    </div>
  );


  let trialCopy = "";
  if (user && !user.isProfessional && !user.isPlus) {  // Only show trial info if they're not plus or pro
    const oneDay = 24 * 60 * 60 * 1000;
    const oneHour = 60 * 60 * 1000;
    const today = new Date();
    const trialEnd = new Date(user.trialEndsAt);
    const timeDiff = trialEnd.getTime() - today.getTime();
    
    // Calculate days, hours
    const diffDays = Math.floor(timeDiff / oneDay);
    const diffHours = Math.floor(timeDiff / oneHour);
    
    if (diffDays < 0) {
      // Trial has expired
      trialCopy = "0 days left";
    } else if (diffDays === 0 && diffHours > 0) {
      // Less than a day but has hours left
      trialCopy = diffHours === 1 ? "1 hour left" : `${diffHours} hours left`;
    } else if (diffDays === 1) {
      trialCopy = "1 day left";
    } else if (diffDays <= 30) {
      trialCopy = `${diffDays} days left`;
    } else if (diffDays <= 90) {
      const weeks = Math.ceil(diffDays / 7);  // Using ceil to round up
      trialCopy = `${weeks} weeks left`;
    }
  }

  const sessionInfo = [
    {
      title: "Self Hosted",
      buttonText: !user 
      ? "Try free for 7 days"  // logged out
      : isInExtendedTrial(user)
        ? "Open a room"  // trial > 90 days
        : isInTrial(user)
          ? "Continue free"  // active trial <= 90 days
          : user.isProfessional || user.isPlus
            ? ""  // pro or plus
            : "Open a room",  // expired trial
      subtitle:
        "Run personalized solo or group readings in our tarot sandbox. Video & audio included.",
        price: !user 
        ? "7-DAY FREE TRIAL"  // logged out
        : isInExtendedTrial(user)
          ? "INCLUDED"  // trial > 90 days
          : isInTrial(user)
            ? "FREE TRIAL"  // active trial <= 90 days
            : user.isProfessional || user.isPlus
              ? "INCLUDED"  // pro or plus
              : "FREE TRIAL",  // expired trial

        trialPrice: !user 
        ? "$11/month after trial"  // logged out
        : isInExtendedTrial(user)
          ? ""  // trial > 90 days
          : isInTrial(user)
            ? "$11/month after trial"  // active trial <= 90 days
            : user.isProfessional || user.isPlus
              ? "subscribed ✨"  // pro or plus
              : "$11/month",  // expired trial

      priceFor: "unlimited sessions",
      trialDetails: !user ? <span style={{ color: '#daeea1' }}>no card needed</span>: trialCopy,
      color: "black",
      textColor: "white",
      onClick: () => {
        document.getElementById("create-room-with-question-button")?.click();

        trackEvent("/home/select_session/self_hosted", {
          theme: currTheme,
          question: currQuestion,
        });
      },
    },
    {
      title: "Async Reading",
      buttonText: "Request a video",
      subtitle:
        "Get clarity on a question through a recorded tarot reading from a vetted pro. Watch it anytime.",
      price: "$25",
      trialPrice: "one-time fee per video",
      priceFor: "15-min video recording",
      color: "#ECD8CE",
      onClick: () => {
        window.location.href = "/book?service=15-min-async&useQuestion=true";
      },
    },
    {
      title: "Live Reading",
      buttonText: "Schedule a reading",
      subtitle:
        "Schedule a live reading or lesson with a vetted pro for a deeper 1:1 conversation.",
      price: "$50+",
      trialPrice: "one-time fee per 1:1",
      priceFor: "30-min or 60-min session",
      color: "#E6E6E6",
      onClick: () => {
        window.location.href = "/book?service=live&useQuestion=true";
      },
    }
  ];

  const sessionSelectionContent = (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        className="session-selection-content"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "36px",
        }}
      >
        {sessionInfo.map((session, i) => (
          <div
            className={`session-option option-${i}`}
            style={{
              backgroundColor: session.color,
              color: session.textColor,
              marginLeft: "8px",
              marginRight: "8px",
              border: "1px solid black",
              borderRadius: "28px",
              padding: "40px 24px 60px 24px",
              width: "200px",
            }}
          >
            <div className="session-title" style={{ fontSize: "18px" }}>
              {session.title}
            </div>

            <div
              className="price-container"
              style={{
                display: "flex",
                alignItems: "baseline",
                marginBottom: "40px",
                marginTop: "10px",
                height: "40px",
                flexDirection: "column",
              }}
            >
              <div
                className="price"
                style={{
                  fontSize: session.price.includes("$") ? "20px" : "15px",
                  marginTop: session.price.includes("$") ? "-2px" : "2px",
                 }
              }
              >
                {session.price}
              </div>

              <div className="trial-details" style={{
              fontSize: "11px",
              fontWeight: "400",
              color: "#e7b6ff",
              marginTop: "2px",
              marginBottom: "-3px",
              }}>
              {session.trialDetails}
              </div>


              {session.priceFor && (
                <div
                  className="price-for"
                  style={{
                    fontSize: "11px",
                    fontWeight: "200",
                    textTransform: "uppercase",
                    marginTop: "10px",
                  }}
                >{`${session.priceFor}`}</div>
              )}
            </div>


            <div className="trial-price" style={{
              whiteSpace: "pre-line",
              fontSize: "11px",
              fontWeight: "400",
              marginTop: "-20px",
              lineHeight: "1.2em",
              marginBottom: "20px",
              }}>
              {session.trialPrice}
              </div>

 
 
            
            <Button
              bookingButton
              onClick={() => {
                if (!user) {
                  setCurrPhase(HOMEPAGE_PHASE.SIGN_IN);
                  setCurrSessionInfo(session);
                } else {
                  if (session.title.toLowerCase().includes("async")) {
                    trackEvent("/home/select_session/async", {
                      theme: currTheme,
                      question: currQuestion,
                    });
                  } else {
                    trackEvent("/home/select_session/live", {
                      theme: currTheme,
                      question: currQuestion,
                    });
                  }
                  session.onClick();
                  createNewQuestion(user.uuid, currQuestion, currTheme);
                }
              }}
              style={{
                backgroundColor: "#DAEEA1",
                height: "40px",
                padding: 0,
                width: "100%",
                fontSize: "12px",
                borderRadius: "12px",
                color: "black",
              }}
            >
              {session.buttonText || "Continue"}
            </Button>

            <div
              className="session-subtitle"
              style={{ fontSize: "12px", marginTop: "30px" }}
            >
              {session.subtitle}
            </div>
          </div>
        ))}
      </div>
      <div
        className="back-button"
        onClick={() => {
          setCurrPhase(HOMEPAGE_PHASE.QUESTION_SELECTION);

          window.setTimeout(() => {
            setCurrQuestion("");
          }, 300);
        }}
      >
        <img style={{ width: "50%" }} src="images/back-nav-icon.svg" />
      </div>
    </div>
  );

  useEffect(() => {
    setIsTransitioning(true);
    setTimeout(() => {
      setIsTransitioning(false);
      document.getElementById("main-container")!.scrollTop = 0;
    }, 800);

    setTimeout(() => {
      if (currPhase === HOMEPAGE_PHASE.THEME_SELECTION) {
        setCurrTitle("Hello!");
        setCurrSubtitle("What are you seeking clarity on?");
        setCurrContent(themeSelectionContent);
      } else if (currPhase === HOMEPAGE_PHASE.QUESTION_SELECTION) {
        setCurrTitle("Anything in mind?");
        setCurrSubtitle("");
        if (
          currQuestion !== "" &&
          currTheme &&
          themes[currTheme].questions &&
          !themes[currTheme].questions.includes(currQuestion)
        ) {
          (
            document.getElementById("question-input") as HTMLInputElement
          ).value = currQuestion;
        }
        setCurrContent(questionSelectionContent);
      } else if (currPhase === HOMEPAGE_PHASE.READING_SELECTION) {
        setCurrTitle(currQuestion ? "Nice question." : "Time for a ritual.");
        setCurrSubtitle("What type of tarot session do you prefer?");
        setCurrContent(sessionSelectionContent);
      } else if (currPhase === HOMEPAGE_PHASE.SIGN_IN) {
        setCurrTitle("");
        setCurrSubtitle("");
        setCurrContent(
          <div style={{ marginTop: "-200px" }}>
            <AuthPage
              state={AuthFormState.REGISTER}
              redirectDest={"/create-room?useQuestion=true"}
              onSubmitSignIn={() => {
                trackEvent("home/question_sign_in", {
                  theme: currTheme,
                  question: currQuestion,
                });

                currSessionInfo.onClick();
              }}
            />
            <div
              className="back-button"
              onClick={() => {
                setCurrPhase(HOMEPAGE_PHASE.READING_SELECTION);
              }}
            >
              <img style={{ width: "50%" }} src="images/back-nav-icon.svg" />
            </div>
          </div>
        );
      }
    }, 600);
  }, [currPhase]);

  useEffect(() => {
    if (currPhase === HOMEPAGE_PHASE.QUESTION_SELECTION) {
      setCurrContent(questionSelectionContent);
    }
  }, [currQuestion]);

  return (
    <StyledMoonlightHomepage
      isConnectingToRoom={isConnecting}
      isTransitioning={isTransitioning}
      style={{
        position: "relative",
        paddingBottom: "200px",
      }}
    >
      <div className="text-container">
        <div
          className={`reading-question-container active-${
            currPhase === HOMEPAGE_PHASE.READING_SELECTION &&
            currQuestion !== ""
          }`}
        >
          {currQuestion}
        </div>
        <div className="title overall-title">{currTitle}</div>

        <div className="subtitle">{currSubtitle}</div>
      </div>
      <div className="content"> {currContent}</div>

      <div>
        <Gradient />
      </div>
    </StyledMoonlightHomepage>
  );
};

const fade1 = keyframes({
  "0%": {
    opacity: 0,
  },
  "10%": { opacity: 0 },
  "60%": { opacity: 1 },
});
const fade2 = keyframes({
  "0%": {
    opacity: 0,
  },
  "35%": { opacity: 0 },
  "85%": { opacity: 1 },
});
const fade3 = keyframes({
  "0%": {
    opacity: 0,
  },
  "60%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const StyledMoonlightHomepage = styled("div", {
  marginTop: "220px",
  width: "550px",

  backgroundColor: "transparent",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  "& .text-container": {
    marginTop: "-50px",
    transition: "all 0.6s ease-out",
    textAlign: "center",
  },
  "& .reading-question-container": {
    width: "70%",
    textAlign: "center",
    padding: "10px 30px",
    borderRadius: "70px",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    fontSize: "10px",

    textWrap: "wrap",

    transition: "opacity 0.6s ease-in",
    transitionDelay: "0.3s",
    position: "absolute",
    opacity: 0,
    top: "-130px",
    left: "50%",
    transform: "translate(-50%, 0)",
    "&.active-true": {
      opacity: 1,
    },
    "@mobile": {
      width: "calc(100vw - 140px)",
    },
  },

  "& .overall-title": {
    marginBottom: "20px",
    fontSize: "46px !important",
    "&.mobile-only": {
      display: "none",
    },
  },

  "& .back-button, & .next-button": {
    padding: "0",
    width: "52px",
    height: "52px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transformOrigin: "center",
  },
  "& .next-button": {
    backgroundColor: "white",
    filter: "invert(1)",
    opacity: 0,
    border: "none",
    marginTop: "12px",
    cursor: "pointer",
    transition: "all 0.3s ease-out",
    position: "absolute",
    top: "34px",
    right: "-60px",
    zIndex: 100,
    transform: "rotate(180deg)",

    margin: "0 auto",
    "&.visible-true": {
      opacity: 1,
    },
    "&:hover": {
      opacity: 0.8,
    },
    "@mobile": {
      position: "fixed",
      top: "62px",
      right: "16px",
      left: "unset",
      margin: 0,
    },
  },
  "& .back-button": {
    backgroundColor: "transparent",

    border: "none",
    position: "absolute",
    top: "-140px",
    left: "-60px",
    zIndex: 100,
    margin: "0 auto",

    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
    "@mobile": {
      top: "62px",
      left: "4px",
      right: "unset",
      position: "fixed",
    },
  },

  "& .skip-button": {
    fontSize: "11px",
    textDecoration: "underline",
    cursor: "pointer",
    marginTop: "20px",
    "&:hover": {
      opacity: 0.8,
    },
  },

  "& .content": {
    transition: "all 0.6s ease-out",
    width: "550px",
    opacity: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@mobile": {
      width: "100vw !important",
    },
  },

  "& .theme-select": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& .theme-button-container": {
      display: "flex",
      flexWrap: "wrap",
      width: "440px",
      marginTop: "20px",
      justifyContent: "space-around",
      "@mobile": {
        width: "340px !important",
      },

      "& button": {
        width: "130px",
        "@mobile": {
          width: "150px !important",
        },
        fontWeight: "400",
        textTransform: "uppercase",
        fontSize: "11px",
        marginBottom: "12px",
        height: "34px",
        borderRadius: "18px",
        padding: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.4)",
        boxShadow: "0px 2px 0px black",
        transition: "all 0.2s",
        "&:hover": {
          boxShadow: "0px 0 0 black",
          transform: "translateY(2px)",
          backgroundColor: "white",
        },
      },
    },
  },

  "& .session-selection-content": {
    "@mobile": {
      width: "90% !important",
      flexDirection: "column",
      alignItems: "center",
      "& .session-option": {
        position: "relative",
        padding: "30px 20px !important",
        marginBottom: "18px",
      },
      "& .price": {
        position: "absolute",
        top: "30px",
        right: "20px",
      },
      "& .price-container": {
        marginTop: "4px !important",
        marginBottom: "-10px !important",
      },
      "& button": {
        marginTop: "16px",
        fontSize: "14px!important"
      },
      "& .session-subtitle": {
        marginTop: "20px !important",
      },
      "& .trial-details": {
        position: "absolute",
        top: "76px",
        right: "20px",
        fontSize: "12px!important"
      },
      "& .trial-price": {
        position: "absolute",
        top: "84px",
        right: "20px",
        fontSize: "12px!important"
      },
    },
  },

  "& .session-option": {
    "@mobile": {
      width: "100% !important",
    },
  },

  "& .crossroads-button-container": {
    display: "flex", // This enables flexbox
    flexWrap: "wrap", // Allows buttons to wrap if the container is too narrow
    justifyContent: "space-around", // Adds space between buttons
    width: "500px", // Set an explicit width for the container
    margin: "0 auto", // Center the container horizontally
    marginTop: "30px",
    "& button": {
      margin: "10px", // Adds margin around the buttons
      padding: "20px 0px", // Space inside the button
      backgroundColor: "rgba(255, 255, 255, 0.1)", // Transparent white
      color: "black",
      border: "1px solid rgba(255, 255, 255, 0.5)", // Transparent black
      borderRadius: "18px",
      cursor: "pointer",
      width: "130px", // Ensure buttons don’t get too wide
      flexShrink: 0, // Prevent buttons from shrinking
      transition: "all 0.3s ease-out",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.4)",
        width: "160px",
      },
    },
  },

  "& .question-select": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "20px",
    width: "84vw !important",
    "& .title.mobile-only": {
      display: "none",
      "@mobile": {
        display: "block",
      },
    },
    "& .question-button-container": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    "& .character-counter": {
      position: "absolute",
      top: "54px",
      right: "38px",
      fontSize: "10px",
      color: "rgba(0, 0, 0, 0.5)",
    },
    "& input": {
      color: "black",
      borderRadius: "24px",
      width: "500px !important",
      "@mobile": {
        width: "100% !important",
      },
      height: "48px !important",
      lineHeight: "1.5",
      fontSize: "13px",
      padding: "14px 58px 14px 20px",
      marginBottom: "12px",
      resize: "none",
      overflowX: "auto",
      overflowY: " hidden",
      whiteSpace: "nowrap",
      boxSize: "border-box",
      border: "1px solid rgba(0, 0, 0, 0.3)",
      backgroundColor: "rgba(255, 255, 255, 0.3)",

      "&::placeholder": {
        color: "rgba(0,0,0,0.7)",
        fontStyle: "italic",
      },
      "&:focus, &.selected-true": {
        outline: "none",
        border: "1px solid black",
        backgroundColor: "white",
      },
      "&:not(:focus):hover": {
        backgroundColor: "rgba(255, 255, 255, 0.6)",
      },
    },
    "& .question-option-container": {
      width: "500px",
      marginTop: "0px",
      fontSize: "13px",
      color: "rgba(0, 0, 0, 0.7)",
      borderRadius: "18px",
      "@mobile": {
        width: "100% !important",

        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      },
      "& .question-option": {
        padding: "14px 20px",
        backgroundColor: "rgba(255, 255, 255, 0.4)",
        marginBottom: "10px",
        borderRadius: "24px",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        fontSize: "11px",
        cursor: "pointer",
        transition: "all 0.2s",
        "@mobile": {
          width: "100%",
        },
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.8)",
        },
        "&.active-true": {
          backgroundColor: "white",
          border: "1px solid black",
          color: "black",
        },
      },
      "&.visible-true": {
        opacity: 1,
      },
      "&.visible-false": {
        opacity: 1,
      },
      transition: "all 0.6s ease-out",
    },
  },
  variants: {
    isConnectingToRoom: {
      true: {
        "& .back-button, & .next-button": {
          opacity: 0,
          pointerEvents: "none",
        },
      },
    },
    isTransitioning: {
      true: {
        "& .content": {
          opacity: 0,
          transition: "all 0.6s ease-out",
        },
        "& .text-container": {
          opacity: 0,
          transition: "all 0.6s ease-out",
        },
      },
      false: {
        "& .content": {
          opacity: 1,
          transition: "all 0.6s ease-in",
        },
        "& .text-container": {
          opacity: 1,
          transition: "all 0.6s ease-in",
        },
      },
    },
  },
});
