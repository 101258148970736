import { useContext, useEffect, useRef } from 'react'
import { AuthContext } from './useAuth'
import useLocation from 'wouter/use-location';

export function useRequireAuth(redirectUrl = '/signin') {
  const auth = useContext(AuthContext)
  let [location ,setLocation] = useLocation();
  const didRedirect = useRef(false)

  useEffect(() => {
    if (didRedirect.current === false) {
      didRedirect.current = true

      if (!auth.user && !location.includes('/signin')) {
        const inviteCode = new URLSearchParams(window.location.search).get('invite');
        let desiredLocation = location;
        if (location === '/create-room' && inviteCode) {
          desiredLocation = `${location}?invite=${inviteCode}`;
        }
        
        const encodedLocation = encodeURIComponent(desiredLocation)
        const deckParam = window.location.href.includes("deck=") ? "&deck=" + window.location.href.split("deck=")[1] : ""
        const spreadParam = window.location.href.includes("spread=") ? "&spread=" + window.location.href.split("spread=")[1] : ""
        const invitedParam = window.location.href.includes("i=") ? "&i=" + window.location.href.split("i=")[1] : ""
        
        setLocation(redirectUrl + "?dest=" + encodedLocation + invitedParam + deckParam + spreadParam)
      }
    }
  }, [auth, redirectUrl])

  return auth
}
