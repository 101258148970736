
import axios from "./axiosConfig.js";

const PAYMENTS_ENDPOINT = "payments/";

interface StripeSecret {
  client_secret: string;
}

export interface PlusSubscription { 
  status: null | string;
  current_period_end?: string;
  cancel_at_period_end?: boolean;
}

export interface SessionPaymentIntentCreateParams {
  proId: string
  amount: number
  description: string
  metadata: Record<string, string>
}

export interface DecksPaymentIntentCreateParams {
  deckIds: string[]
  metadata: Record<string, string>
}

export interface OnboardConnectAccountParams {
  country: string
}

export interface PlusSubscriptionPaymentIntentParams {
  promotionCode?: string;
}


const createPortalSession = async (returnEndUrl: string) => {
  const host = window.location.origin;
  const returnUrl = host + returnEndUrl;
  const response = await axios.post(
    PAYMENTS_ENDPOINT + "create-portal-session/",
    { returnUrl }
  );
  if (response.status === 200) {
    const url = response.data.url;
    
    // Check if on mobile device
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    
    if (isMobile) {
      // For mobile, redirect in same window for better compatibility
      window.location.href = url;
    } else {
      // For desktop, continue to open in new tab as before
      window.open(url, "_blank");
    }
  } else {
    throw new Error(response.statusText);
  }
};

const getPlusSubscription = async (): Promise<PlusSubscription> => {
  const response = await axios.get(PAYMENTS_ENDPOINT + "plus-subscription/");
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(response.statusText);
  }
};

const getDeckSubscription = async(deckId: string): Promise<PlusSubscription> => {
  const response = await axios.get(PAYMENTS_ENDPOINT + "deck-subscription/" + deckId + "/");
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(response.statusText);
  }
};

const createSessionPaymentIntent = async (params: SessionPaymentIntentCreateParams): Promise<{proId: string, paymentIntentId: string, clientSecret: string}> =>  {
  const response = await axios.post(PAYMENTS_ENDPOINT + "create-session-payment-intent", params);
  if (response.status === 200) {
    const { proId, paymentIntentId, clientSecret } = response.data;
    return { proId, paymentIntentId, clientSecret }
  } else {
    throw new Error(response.statusText);
  }
}

const createDecksPaymentIntent = async (params: DecksPaymentIntentCreateParams): Promise<{paymentIntentId: string, clientSecret: string, paymentGroupId: string, deckIds: string[]}> => {
  const response = await axios.post(PAYMENTS_ENDPOINT + "create-decks-payment-intent", params);
  if (response.status === 200) {
    const {paymentIntentId, clientSecret, paymentGroupId, deckIds} = response.data;
    return {paymentIntentId, clientSecret, paymentGroupId, deckIds}
  } else {
    throw new Error(response.statusText);
  }
}

const createPlusSubscriptionPaymentIntent = async (params?: PlusSubscriptionPaymentIntentParams): Promise<{subscriptionId: string, clientSecret: string}> => {
  const response = await axios.post(
    PAYMENTS_ENDPOINT + "create-plus-payment-intent/",
    params // Pass the promotion code to the backend
  );
  if (response.status === 200) {
    const { subscriptionId, clientSecret } = response.data;
    return { subscriptionId, clientSecret };
  } else {
    throw new Error(response.statusText);
  }
};

const onboardConnectAccount = async (params: OnboardConnectAccountParams): Promise<void> => {
  const response = await axios.post(PAYMENTS_ENDPOINT + "onboard-connect-account", params);
  if (response.status === 200) {
    const {onboardingLink} = response.data

    window.location.href = onboardingLink;
  } else {
    console.error('Unexpected response:',response);
    throw new Error(response.statusText);
  }
}

const unlinkConnectAccount = async () => {
  const response = await axios.post(PAYMENTS_ENDPOINT + "unlink-connect-account");
  if (response.status !== 200) {
    console.error('Unexpected response:',response);
  }
}

const validatePromotionCode = async (code: string): Promise<{
  valid: boolean;
  message?: string;
  discountInfo?: {
    percentOff?: number;
    amountOff?: number;
    duration?: 'once' | 'forever' | 'repeating';
    durationInMonths?: number | null;
    maxRedemptions?: number | null;
    appliesToSubscriptions?: boolean;
  };
}> => {
  try {
    console.log('Validating code:', code.trim());
    console.log('Endpoint:', PAYMENTS_ENDPOINT + "validate-promotion-code/");
    
    const response = await axios.post(PAYMENTS_ENDPOINT + "validate-promotion-code/", { 
      code: code.trim() 
    });
    
    console.log('Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Validation error:', error);
    throw error; // Re-throw to be handled by the component
  }
};

export { getDeckSubscription, createPlusSubscriptionPaymentIntent, createPortalSession, getPlusSubscription, createSessionPaymentIntent, createDecksPaymentIntent, onboardConnectAccount, unlinkConnectAccount, validatePromotionCode };
