import { CheckIcon } from "@radix-ui/react-icons";
import { Button } from "../components/Button";
import { useMutateStorage, useStorage } from "../../state/liveblocks.config";
import { RoomTheme } from "../../types/room/types";
import { localState } from "~/state/state";
import { StyledLeftListItem, StyledLeftMenu } from "./StyledMenus";

import { useState } from "react";
import { useSnapshot } from "valtio";
import { styled } from "~/ui/style/stitches.config";
import { imageUrl } from "~/utils/imageurl";
import { trackEvent } from "~/api/analyticsApi";

export const allBackgrounds = [
  {
    name: "Light",
    filename: "",
    preference: "light",
    preview: "light-preview.jpg",
  },
  {
    name: "Dark",
    filename: "",
    preference: "dark",
    preview: "dark-preview.jpg",
  },
  {
    name: "Day",
    filename: "sky.jpg",
    preference: "light",
    preview: "sky-preview.jpg",
  },
  {
    name: "Sunset",
    filename: "sunset.jpg",
    preference: "light",
    preview: "sunset-preview.jpg",
  },
  {
    name: "Night",
    filename: "nightsky7.jpg",
    preference: "dark",
    preview: "night-preview.jpg",
  },
  {
    name: "Crystal",
    filename: "crystalcave.jpg",
    preference: "light",
    preview: "crystal-preview.jpg",
  },
  {
    name: "Wood",
    filename: "wood.jpg",
    preference: "light",
    preview: "wood-preview.jpg",
  },
  {
    name: "Rock",
    filename: "rockcave.jpg",
    preference: "dark",
    preview: "rock-preview.jpg",
  },
  {
    name: "Silk",
    filename: "blacksilk.jpg",
    preference: "dark",
    preview: "dark-preview.jpg",
    isPlus: true,
  },
  {
    name: "Velvet",
    filename: "velvet.jpg",
    preference: "light",
    preview: "velvet-preview.jpg",
    isPlus: true,
  },
  {
    name: "Aurora",
    filename: "aurora.jpg",
    preference: "dark",
    preview: "aurora-preview.jpg",
    isPlus: true,
  },
  {
    name: "Rug",
    filename: "rug.jpg",
    preference: "dark",
    preview: "rug-preview.jpg",
    isPlus: true,
  },
  {
    name: "Grass",
    filename: "grass.jpg",
    preference: "dark",
    preview: "grass-preview.jpg",
    isPlus: true,
  }
];

export function BackgroundMenu({
  isOpen,
  setIsOpen,
  onSpreadChanged,
}: {
  isOpen: boolean;
  setIsOpen: Function;
  onSpreadChanged: Function;
}) {
  const updateStorage = useMutateStorage();
  const currentBackground = useStorage((root) => root.background);
  const theme = useStorage((root) => root.theme);
  const [showSpreadImporter, setShowSpreadImporter] = useState(false);

  const { isOnPlusPlan } = useSnapshot(localState);

  const firstPlusIdx = allBackgrounds.findIndex((b) => b.isPlus);

  const deckSelectMenu = (
    <StyledLeftMenu
      id={"shuffle-deck"}
      scrollable={true}
      Theme={theme}
      style={{
        maxHeight: "300px",
        marginBottom: 0,
      }}
    >
      <Button
        Theme={theme}
        role="menuitem"
        className="arrow-button"
        style={{}}
        transparent
        onClick={() => {
          setIsOpen(false);
        }}
      >
        &larr;
      </Button>

      {allBackgrounds.map((background, i) => (
        <div key={i} style={{ position: "relative" }}>
          {background.isPlus && i === firstPlusIdx && (
            <div
              className="plus-separator"
              style={{
                width: "calc(100% - 2px)",
                position: "relative",
                height: "1px",
                backgroundColor: "rgba(127, 127, 127, 0.5)",
                marginBottom: "11px",
                marginTop: "3px",
              }}
            >
              <PlusBadge
                clickable
                isOnPlusPlan={isOnPlusPlan}
                Theme={theme}
                style={{ position: "absolute", right: "12px", top: "-7px" }}
              >
                NEW
              </PlusBadge>
            </div>
          )}

          <StyledLeftListItem
            key={i}
            Theme={theme}
            style={{ position: "relative" }}
          >
            {background.preview && (
              <img
                style={{
                  width: "30px",
                  height: "30px",
                  position: "absolute",
                  left: "-44px",
                  borderRadius: "50%",
                  opacity: 0,
                }}
                className="preview"
                src={imageUrl(
                  "/backgrounds/previews/" + background.preview,
                  60
                )}
              />
            )}
            <Button
              Theme={theme}
              transparent
              transparentHover
              isPlus={background.isPlus && !isOnPlusPlan}
              menuOption
              onClick={() => {
                trackEvent("/room/choose_background", {
                  bgname: background.name,
                });
                if (!isOnPlusPlan && background.isPlus) {
                  // open new tab with pricing window
                  window.open("/plans", "_blank");
                  return;
                }
                updateStorage((storage) => {
                  storage.set("background", background);
                });
                if (background.preference) {
                  updateStorage((storage) => {
                    storage.set("theme", background.preference as RoomTheme);
                  });
                }
                setIsOpen(false);
                onSpreadChanged();
              }}
            >
              {background.name}
              {}
              {!isOnPlusPlan && background.isPlus ? (
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    marginRight: "-6px",
                  }}
                >
                  <img
                    className="link-out-img"
                    src="/images/room/link-out.svg"
                    style={{
                      height: "15px",
                      width: "15px",
                    }}
                  />
                </div>
              ) : (
                <CheckIcon
                  style={{
                    opacity: background.name === currentBackground.name ? 1 : 0,
                    flexShrink: 0,
                    marginRight: "-12px",
                  }}
                />
              )}
            </Button>
          </StyledLeftListItem>
        </div>
      ))}
    </StyledLeftMenu>
  );

  return (
    <div>
      {isOpen ? (
        deckSelectMenu
      ) : (
        <Button
          Theme={theme}
          className="nav-button nav-bar-button"
          style={{
            backgroundColor:
              theme === "light"
                ? "hsla(0,0%, 92%, 0.8)"
                : "hsla(0, 0%, 10%, 0.7)",
            padding: "4px 6px 4px 4px",
            marginLeft: "-1px",
            boxShadow: "none",
            borderRadius: "6px",
          }}
          smallTransparent
          onClick={() => {
            setIsOpen(true);
          }}
        >
          + Select spread
        </Button>
      )}
    </div>
  );
}

export const PlusBadge = ({
  style,
  className,
  isOnPlusPlan,
  isProBadge,
  Theme,
  children,
  clickable,
  purple,
}: {
  style?: Object;
  className?: string;
  isOnPlusPlan?: boolean;
  isProBadge?: boolean;
  Theme?: RoomTheme;
  children: string;
  clickable?: boolean;
  purple?: boolean;
}) => {
  return (
    <StyledPlusBadge
      className={className}
      style={style}
      isOnPlusPlan={isOnPlusPlan}
      purple={purple}
      isProBadge={isProBadge}
      Theme={Theme ? Theme : "light"}
      clickable={clickable && !isOnPlusPlan}
      onClick={() => {
        if (clickable && !isOnPlusPlan) window.open("/plans", "_blank");
      }}
    >
      {children}
    </StyledPlusBadge>
  );
};

const StyledPlusBadge = styled("div", {
  backgroundColor: "black",
  fontSize: "8px",
  fontStyle: "italic",
  color: "white",
  width: "33px",
  height: "15px",
  borderRadius: "5px",
  border: "1px solid rgba(127, 127, 127, 0.5)",
  display: "flex",
  paddingRight: "1px",
  paddingBottom: "1px",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: `$lightPurpleSmallShadow`,
  variants: {
    Theme: {
      light: {},
      dark: {
        backgroundColor: "white",
        color: "black",
      },
    },
    isOnPlusPlan: {
      true: {
        boxShadow: "none",
      },
    },
    purple: {
      true: {
        backgroundColor: "$lightPurple",
        boxShadow: "none",
        border: "none",
        color: "black",
      },
    },
    clickable: {
      true: {
        cursor: "pointer",
        transition: "opacity 0.2s ease-out",
        "&:hover": {
          opacity: "0.8",
        },
      },
    },
    isProBadge: {
      true: {
        backgroundColor: "$gray100",
        color: "black",
        boxShadow: "none",
        border: "none",
      },
    },
  },
  compoundVariants: [
    {
      Theme: "light",
      isOnPlusPlan: true,
      css: {
        backgroundColor: "$gray200",
        color: "black",
        border: "none",
      },
    },
    {
      Theme: "dark",
      isOnPlusPlan: true,
      css: {
        backgroundColor: "$gray700",
        color: "white",
        border: "none",
      },
    },
  ],
});
