import { allDecks } from "~/ui/menus/DeckMenu";
import { StyledProDirectory } from "./ProDirectory";
import { Deck, freeDeckIds } from "~/types/room/types";
import { useContext, useEffect, useRef, useState } from "react";
import { getUserByUsername } from "~/api/userApi";
import { keyframes, styled } from "~/ui/style/stitches.config";
import { AuthContext } from "~/utils/useAuth";
import { Button } from "~/ui/components/Button";
import { DECK_PRICE } from "~/utils/consts";
import { CartOverlay } from "./DeckOverlay";
import { CartPage, localState } from "~/state/state";
import { useLocation } from "wouter";
import { useSnapshot } from "valtio";
import { isElementInViewport } from "~/utils/viewport";
import { imageUrl, mp4Url } from "~/utils/imageurl";
import { trackEvent } from "~/api/analyticsApi";

export const deckNameToDeckPageId = (deckName: string) => {
  return deckName.toLowerCase().split(" ").join("-");
};

export const deckPageIdToDeckName = (deckPageId: string) => {
  return deckPageId
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const DeckAnimation = ({
  deck,
  isHovered,
}: {
  deck: Deck;
  isHovered: boolean;
}) => {
  const boxMargin = 4;
  const boxW = deck.cardSize[0] / 2 + boxMargin * 2;
  const boxH = deck.cardSize[1] / 2 + boxMargin * 2;
  const boxD = 50;
  const numCardsInBox = 36;

  const [isLoadingBoxArt, setIsLoadingBoxArt] = useState(true);

  // preload box art
  useEffect(() => {
    const boxArt = new Image();
    // todo: this is so hacky im sry future me
    // fix the weirdly cursed mushroom tarot box art by making it a PNG
    // the jpg is not registering in our CDN
    (boxArt.src = imageUrl(
      `${deck.basePath}/${
        deck.name.toLowerCase().includes("mushroom")
          ? "box.png"
          : deck.name.toLowerCase().includes("mixed")
          ? "boxart.png"
          : "box.jpeg"
      }`,
      270
    )),
      (boxArt.onload = () => {
        setIsLoadingBoxArt(false);
      });
  }, [deck.basePath]);

  if (!deck.shopCardNames) return <></>;
  const shopCards = deck.shopCardNames.map((cardName) => {
    return deck.cards.find((card) => card.name === cardName);
  });

  const numHoverCards = deck.shopCardNames.length;
  const xOffset = boxD / numCardsInBox;
  const yOffset = boxD / numCardsInBox;

  const boxCards = [
    ...shopCards.filter((card) => card !== undefined),
    ...[...deck.cards, ...deck.cards, ...deck.cards]
      .filter((card) => !shopCards.includes(card))
      .map((card, i) => {
        if (i > numHoverCards && i < numCardsInBox) return card;
      }),
  ]
    .reverse()
    .filter((card) => card !== undefined);

  const cards = boxCards.map((card, i) => {
    if (!card) return <></>;
    const shouldHover = shopCards.find(
      (shopCard) => shopCard?.name === card.name
    );
    return (
      <div
        className={`card-hover-container ${
          isHovered && shouldHover ? "hovered" : ""
        }`}
        key={card.filePath + deck.basePath + i}
        style={{
          animationDelay: shouldHover
            ? `${(numCardsInBox - i - 2) * 0.8}s`
            : `0`,
          width: `${deck.cardSize[0] / 2}px`,
          height: `${deck.cardSize[1] / 2}px`,
          transformOrigin: `${deck.cardSize[0] / 4}px`,
          zIndex: -1,
          transitionDelay: `${0.2 * (numCardsInBox - i)}s`,
          marginLeft: `${boxMargin}px`,
        }}
      >
        <div
          className="card front"
          style={{
            background: `${
              shouldHover
                ? `url(${imageUrl(
                    `${deck.basePath}/${card.artwork}`,
                    270
                  )}) 100% 100% / contain no-repeat`
                : `url(${imageUrl(
                    `${deck.basePath}/${
                      deck.cards[card.description.length % numHoverCards]
                        .artwork
                    }`,
                    270
                  )}) 100% 100% / contain no-repeat`
            }`,
            border: `0.5px solid ${
              deck.boxColors?.border || deck.borderColor.light || "black"
            }`,
            borderRadius: "13px",
            backgroundPosition: "center",
            backgroundSize: "contain",
            width: "100%",
            height: "100%",
            transform: `translateX(${xOffset * i}px) translateY(${
              yOffset * i
            }px) translateZ(1px)`,
            position: "absolute",
            top: `${-boxMargin}px`,
            left: `${boxMargin}px`,
          }}
        ></div>
      </div>
    );
  });

  const box = (
    <div className="box-container">
      <div
        className={`box box-front ${isLoadingBoxArt ? "loading" : ""}`}
        style={{
          width: `${boxW}px`,
          height: `${boxH}px`,
          background: isLoadingBoxArt
            ? deck.boxColors
              ? deck.boxColors.left
              : "black"
            : `url(${imageUrl(
                // this is hacky: TODO just add a new field in json
                // fix the weirdly cursed mushroom tarot box art by making it a PNG
                // the jpg is not registering in our CDN
                `${deck.basePath}/${
                  deck.name.toLowerCase().includes("mushroom")
                    ? "box.png"
                    : deck.name.toLowerCase().includes("mixed")
                    ? "boxart.png"
                    : "box.jpeg"
                }`,
                270
              )}) 100% 100% / cover no-repeat`,
          backgroundPosition: "center",
          position: "absolute",
          borderTop: "0.5px solid black",
          borderRight: "0.5px solid black",
          borderBottom: "0.5px solid black",
          borderLeft: "0.5px solid black",
          borderRadius: "0px 2px 2px 2px",
          zIndex: 10,
          marginTop: `${boxMargin + yOffset * numCardsInBox}px`,
          marginLeft: `${boxMargin + xOffset * numCardsInBox}px`,
        }}
      ></div>
      <div
        className="box box-side"
        style={{
          width: `${boxD}px`,
          height: `${boxH}px`,
          borderTop: "0.5px solid #00000070",
          borderRight: "0.5px solid #00000070",
          borderBottom: "0.5px solid #00000070",
          borderLeft: "0.5px solid #00000070",
          borderRadius: "2px 0 2px 2px",

          background: deck.boxColors
            ? deck.boxColors.left
            : "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(100,100,100,1) 100%)",
          transform: `skewY(45deg)`,

          transformOrigin: "top right",
          position: "absolute",
          marginTop: `${boxMargin + yOffset * numCardsInBox}px`,
          marginLeft: `${boxMargin + 0.5}px`,
        }}
      ></div>
      <div
        className="box box-back-side"
        style={{
          width: `${boxD}px`,
          height: `${boxH}px`,
          backgroundColor: deck.boxColors ? deck.boxColors.right : "black",
          transform: `skewY(45deg)`,
          borderRadius: " 2px",
          borderTop: "0.5px solid black",
          transformOrigin: "top right",
          position: "absolute",
          marginTop: `${boxMargin + yOffset * numCardsInBox}px`,
          marginLeft: `${boxW + boxMargin}px`,
          zIndex: -2,
        }}
      ></div>
      <div
        className="box box-back"
        style={{
          width: `${boxW}px`,
          height: `${boxH}px`,
          borderTop: "0.5px solid black",

          backgroundColor: deck.boxColors ? deck.boxColors.back : "black",
          position: "absolute",
          marginTop: `${boxMargin}px`,
          marginLeft: `${boxMargin}px`,
          borderRadius: "2px",

          zIndex: -3,
        }}
      ></div>
    </div>
  );

  return (
    <StyledDeckAnimation
      className={`cards ${isHovered ? "hovered" : ""}`}
      style={{
        width: `${deck.cardSize[0] / 2}px`,
        height: `${deck.cardSize[1] / 2}px`,
        marginLeft: `${-boxD}px`,
      }}
    >
      {box}
      {cards}
      <div
        className="bottom-shadow"
        style={{
          height: `${boxD}px`,
          marginLeft: `${boxD / 2}px`,
        }}
      ></div>
    </StyledDeckAnimation>
  );
};

const floatAndRotate = keyframes({
  "40%": {
    transform: "translate3d(0, 0, 0)",
    zIndex: 1,
  },
  "50%": {
    transform: "translate3d(100%, 0, 0)",
    zIndex: 1,
  },
  "51%": {
    transform: "translate3d(100%,0, 0)",
    zIndex: -1,
  },
  "60%": {
    transform: "translate3d(0px, 0, 0)",
    zIndex: -1,
  },
  "100%": {
    transform: "translate3d(0px, 0, 0)",
    zIndex: -1,
  },
});

const LoadingPulse = keyframes({
  // "0%": {
  //   filter: "brightness(1.05)",
  // },
  // "50%": {
  //   filter: "brightness(1.2)",
  // },
  // "100%": {
  //   filter: "brightness(1.05)",
  // },
});

const StyledDeckAnimation = styled("div", {
  transition: "all 0.3s ease",

  position: "relative",
  cursor: "pointer",
  transform: "rotateX(-30deg) rotateY(20deg)",
  height: "280px",
  marginBottom: "90px",

  "& .box": {
    transition: "top 0.4s",
    top: 0,
    left: 0,
  },

  "& .box-front": {
    backgroundSize: "cover",
  },

  "& .box-front.loading": {
    filter: "brightness(0.92)",
  },

  "&.hovered .box": {
    top: "70px",
  },

  "& .bottom-shadow": {
    position: "absolute",
    width: "100%",
    bottom: "-130px",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    margin: "auto",
    transform: "skewX(45deg)",
    filter: "blur(12px)",
    transition: "filter 0.4s",
    zIndex: -1,
  },

  "& .card-hover-container": {
    position: "absolute",
    transformStyle: "preserve-3d",
    top: 0,
    transition: "top 0.5s",

    "&.hovered": {
      animation: `${floatAndRotate} 4s ease-in-out`,
      top: "-180px",
    },
  },
  "@mobile": {
    transform: "scale(0.8) rotateX(-30deg) rotateY(20deg)",
    transformOrigin: " bottom center",
  },
});

export const DeckMarketplaceItem = ({
  deck,
  onClick,
  isFree,
}: {
  deck: Deck;
  onClick: Function;
  isFree?: boolean;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { user } = useContext(AuthContext);
  const { isOnMobile } = useSnapshot(localState);
  const id = "deck-item-" + deck.id;
  const [shouldPlayAnimationMobile, setShouldPlayAnimationMobile] =
    useState(false);

  useEffect(() => {
    if (isOnMobile && document.getElementById(id)) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setShouldPlayAnimationMobile(true);
          } else {
            setShouldPlayAnimationMobile(false);
          }
        },
        { threshold: 0.5 }
      );
      observer.observe(document.getElementById(id) as HTMLElement);
    }
  }, [isOnMobile, id]);

  return (
    <StyledDeckMarketplaceItem
      id={id}
      onClick={() => {
        onClick();
      }}
      hovered={isHovered || shouldPlayAnimationMobile}
      onPointerEnter={() => {
        if (!isOnMobile) setIsHovered(true);
      }}
      onPointerLeave={() => {
        if (!isOnMobile) setIsHovered(false);
      }}
    >
      <div className="deck-animation-container">
        <DeckAnimation
          deck={deck}
          isHovered={isHovered || shouldPlayAnimationMobile}
        />
      </div>
      <div className="deck-info">
        <h3 className="deck-name">{deck.name}</h3>
        <p className="creator">{deck.creator}</p>
        <p className="digital-line">{`digital ${
          deck.name.toLowerCase().includes("viator") ? "oracle" : "tarot"
        } deck`}</p>
            <p
            className={`price in-collection-${
              user?.decks?.includes(deck.id) || isFree
            }`}
          >
            {user?.decks?.includes(deck.id) && !isFree
              ? "✓ COLLECTED"
              : isFree
              ? "✓ INCLUDED FREE"
              : "$" + DECK_PRICE}
          </p>
      </div>
    </StyledDeckMarketplaceItem>
  );
};

const StyledDeckMarketplaceItem = styled("div", {
  width: "calc(33% - 10px)",
  margin: "5px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  height: "auto",
  borderRadius: "16px",
  transition: "all 0.3s ease",
  marginBottom: "40px",

  "& .deck-info": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "70px",
    marginBottom: "20px",
    "& .deck-name": {
      fontFamily: "GorgeousBaby",
      fontSize: "32px",
      fontWeight: "normal",
      marginBottom: "2px",
    },
    "& .creator": {
      fontSize: "13px",
    },
    "& .digital-line": {
      fontSize: "12px",
      fontStyle: "italic",
      marginTop: "2px",
    },
    "& .price": {
      fontSize: "15px",
      marginTop: "2px",
      "&.in-collection-true": {
        fontSize: "12px",
        fontWeight: "300",
        marginTop: "6px",
        color: "#5d5d5d",
      },
    },
  },
  "@bp900": {
    width: "calc(50% - 10px)",
  },
  "@mobile": {
    width: "100%",

    "& .deck-info": {
      "& .deck-name": {
        fontSize: "34px",
      },
      "& .creator": {
        fontSize: "15px",
      },
      "& .digital-line": {
        fontSize: "14px",
      },
      "& .price": {
        fontSize: "18px",
        "&.in-collection-true": {
          fontSize: "15px",
        },
      },
    },
    "& .deck-animation-container": {
      transform: "scale(0.9)",
      transformOrigin: "bottom center",
      marginTop: "-10px",
    },
  },
  variants: {
    hovered: {
      true: {
        zIndex: 3,
        backgroundColor: "$gray200",
        "& .bottom-shadow": {
          filter: "blur(3px) !important",
        },
      },
    },
  },
});

export const DeckMarketplace = ({ deckPageId }: { deckPageId?: string }) => {
  const [userDecks, setUserDecks] = useState<string[]>([]);
  const { user: authUser } = useContext(AuthContext);
  const [_, setLocation] = useLocation();
  const { cartPage, recentCartDeck } = useSnapshot(localState);

  useEffect(() => {
    if (!deckPageId) return;
    const deckName = deckPageIdToDeckName(deckPageId);
    const deck = allDecks.find(
      (d) => d.name.toLowerCase() === deckName.toLowerCase()
    );
    if (deck) {
      // open modal
      localState.recentCartDeck = deck;
      localState.cartPage = CartPage.DECK;
    }
  }, [deckPageId]);

  useEffect(() => {
    if (cartPage === CartPage.DECK && recentCartDeck) {
      setLocation(`/decks/${deckNameToDeckPageId(recentCartDeck.name)}`);
    } else {
      // remove /deck from url, whether we're at the /decks page or not
      setLocation("/decks");
    }
  }, [cartPage, recentCartDeck]);

  useEffect(() => {
    // set user decks
    (async () => {
      if (authUser?.username) {
        const user = await getUserByUsername(authUser.username);
        if (user) {
          setUserDecks([...user.decks]);
        }
      }
    })();
    trackEvent("/decks/visited_page");
  }, [authUser?.username]);

  return (
    <StyledProDirectory
      style={{
        paddingBottom: "200px",
        overflowX: "hidden",
      }}
    >
      <div className="text-container dark-header decks-header">
        <div className="text">
          <h1 className="title">Shop digital decks</h1>
          <h3 className="sub-subtitle">
            Carry interactive digital editions of beloved tarot & oracle decks
            in your Moonlight rooms, for solo or collaborative rituals. Each
            deck comes with its own guidebook.
          </h3>
          <div className="buttons-container">
            <Button
              style={{
                padding: "14px 10px",
                width: "170px",
                maxWidth: "160px",
                marginRight: "6px",
                border: "1px solid white",
              }}
              onClick={() => {
                window.open(
                  "https://blog.moonlight.world/about-our-digital-tarot-decks/",
                  "_blank"
                );
              }}
            >
              About the artists
            </Button>
          </div>
        </div>
        <div
          className="bookings-header-img-container"
          style={{ position: "relative" }}
        >
          <div
            className="scrolling-overlay"
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              top: 0,
              left: 0,
              zIndex: 99,
            }}
          ></div>
          <video
            className="bookings-header-img"
            autoPlay
            loop
            muted
            playsInline
            onScroll={(e) => {
              e.preventDefault();
            }}
            src={mp4Url("/moonlight-decks-demo.mp4", "medium")}
          ></video>
        </div>
      </div>

      <StyledDeckMarketplaceSection>
        {allDecks
          .filter((deck) => !freeDeckIds.includes(deck.id))
          .map((deck, i) => {
            return (
              <DeckMarketplaceItem
                deck={deck}
                key={deck.name + "deck-marketplace-item"}
                onClick={() => {
                  localState.cartPage = CartPage.DECK;
                  localState.recentCartDeck = deck;
                }}
              />
            );
          })}

        {allDecks
          .filter((deck) => freeDeckIds.includes(deck.id))
          .map((deck, i) => {
            return (
              <DeckMarketplaceItem
                deck={deck}
                key={deck.name + "deck-marketplace-item"}
                isFree
                onClick={() => {
                  localState.cartPage = CartPage.DECK;
                  localState.recentCartDeck = deck;
                }}
              />
            );
          })}
      </StyledDeckMarketplaceSection>
    </StyledProDirectory>
  );
};

const StyledDeckMarketplaceSection = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  marginTop: "30px",
  marginBottom: "120px",
  width: "calc(90% - 24px)",
});

export const MobileOnlyBr = styled("br", {
  display: "none !important",
  "@bp3": {
    display: "block !important",
  },
  variants: {
    bigger: {
      true: {
        "@bp1": {
          display: "block !important",
        },
      },
    },
  },
});
