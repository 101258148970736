import { keyframes, styled } from "~/ui/style/stitches.config";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "wouter";
import {
  PlusSubscription,
  createPortalSession,
  getPlusSubscription,
} from "~/api/paymentsApi";
import { Button } from "~/ui/components/Button";
import { PlusBadge } from "~/ui/menus/BackgroundMenu";
import { AuthContext } from "~/utils/useAuth";
import { HelpButton } from "~/ui/components/HelpButton";
import { localState } from "~/state/state";
import { useSnapshot } from "valtio";
import { SimpleLoadingIndicator } from "../loading/LoadingPage";
import { stripeTimestampToReadable } from "~/utils/datetime";
import { trackEvent } from "~/api/analyticsApi";
import { SocialBottomBar } from "~/ui/home/HomeNavBar";
import { SpinStar } from "../../ui/components/SpinStar";
import { FAQ, FAQItemProps } from "~/ui/components/FAQ";
import { BillingOverlay } from "./BillingOverlay";

export const Success = () => {
  return (
    <StyledSuccessPage>
      <div className="success-container">
        <h1>Thanks for subscribing! ✨</h1>
        <p>
          Now you're ready to create tarot rooms whenever inspiration strikes,
          host friends for group readings, or reflect deeply when you need
          clarity.
        </p>
        <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          <button
            onClick={() => (window.location.href = "/")}
            className="continue-button"
            style={{
              width: "150px",
              height: "45px",
              color: "white",
              backgroundColor: "black",
              border: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "400",
              fontSize: "13px",
              cursor: "pointer",
            }}
          >
            Return home
          </button>
          <button
            onClick={() => (window.location.href = "/create-room")}
            className="continue-button"
            style={{
              width: "150px",
              height: "45px",
              color: "black",
              backgroundColor: "white",
              border: "1px solid black",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "400",
              fontSize: "13px",
              cursor: "pointer",
            }}
          >
            Open a room
          </button>
        </div>
      </div>
    </StyledSuccessPage>
  );
};

const StyledSuccessPage = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  height: "100vh",
  backgroundColor: "$wash",
  "& .continue-button": {
    transition: "all 0.2s ease-in-out",
    boxShadow: "rgb(213, 181, 253) 0px 0px 10px 1px",
    borderRadius: "10px",
    "&:hover": {
      boxShadow: "rgb(213, 181, 253) 0px 0px 15px 3px",
      opacity: ".8 !important",
    },
  },
  "& .success-container": {
    marginTop: "-16px",
    backgroundColor: "white",
    boxShadow: "0px 3px 0px #000000",
    border: "1px solid black",
    borderRadius: "36px",
    width: "450px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "270px",

    "& h1": {
      width: "321px",
      textAlign: "center",
      fontSize: "16px",
      fontWeight: "500",
    },
    "& p": {
      marginBottom: "42px",
      marginTop: "18px",
      width: "321px",
      textAlign: "center",
      fontSize: "13px",
      lineHeight: "1.4",
    },
  },

  "@mobile": {
    "& p": {
      width: "90%!important",
      height: "auto",
    },
    "& .success-container": {
      width: "90%!important",
      height: "auto",
      padding: "30px",
      borderRadius: "24px",
    },
  },
});

const Feature = ({
  header,
  text,
  img,
  isPlus,
}: {
  header?: string;
  text: string;
  img: string;
  isPlus?: boolean;
}) => {
  return (
    <div className="feature">
      <div className="feature-line">
      <img src={img} />
      <div className="feature-header">{header}</div>
        <div>{text}</div>
      </div>
      {isPlus ? (
        <PlusBadge
          style={{
            boxShadow: "none",
            paddingTop: "1px",
            color: "white",
            backgroundColor: "#535353",
          }}
          className="plus"
        >
          NEW
        </PlusBadge>
      ) : (
        <div></div>
      )}
    </div>
  );
};

const PlanInfo = ({
  name,
  price,
  description,
  isPro,
}: {
  name: string;
  price: string;
  description: string;
  isPro?: boolean;
}) => (
  <div className="plan-info">
    <h1>{name}</h1>

    <h2
      style={{
        textDecoration: isPro ? "line-through" : "unset",
        textDecorationThickness: isPro ? "1px" : "unset",
      }}
    >
      <span style={{ fontSize: "18px", fontWeight: "400" }}>$11</span>
      <span style={{ fontSize: "13px", fontWeight: "400" }}>/month </span>
    </h2>

    <p
      dangerouslySetInnerHTML={{
        __html: isPro ? "included free for pros" : description,
      }}
    ></p>
  </div>
);

export const BillingPage = () => {
  const { user } = useContext(AuthContext);
  const [_, setLocation] = useLocation();
  
  const handleCreatePortal = async () => {
    try {
      // Set a timeout to reset the loading state
      // This will run before the page redirects
      setTimeout(() => {
        setIsLoadingStripe(false);
      }, 800);
      
      await createPortalSession("/plans");
    } catch (error) {
      console.error("Stripe portal error");
      setIsLoadingStripe(false);
    }
  };

  const { isOnMobile } = useSnapshot(localState);

  const [isOnPlus, setIsOnPlus] = useState(false);
  const [isPro, setIsPro] = useState(false);

  const [isLoadingStripe, setIsLoadingStripe] = useState(false);
  const [plusSubscription, setPlusSubscription] = useState<PlusSubscription>({
    status: null,
  });
  const [hasLoadedPlanData, setHasLoadedPlanData] = useState(false);
  const [subscriptionString, setSubscriptionString] = useState<string>("");
  const [showStripeCheckout, setShowStripeCheckout] = useState(false);

  useEffect(() => {
    if (plusSubscription.status === "active") {
      const readableTimestamp = stripeTimestampToReadable(
        plusSubscription.current_period_end
      );
      if (plusSubscription.cancel_at_period_end) {
        setSubscriptionString("expires " + readableTimestamp);
      } else {
        setSubscriptionString("renews " + readableTimestamp);
      }
    } else if (user && user.trialEndsAt && !user.isPlus) {  // Only show trial info if they're not Plus
      const now = new Date();
      const trialEnd = new Date(user.trialEndsAt);
      const timeDiff = trialEnd.getTime() - now.getTime();
      
      const oneDay = 24 * 60 * 60 * 1000;
      const oneHour = 60 * 60 * 1000;
      const diffDays = Math.floor(timeDiff / oneDay);
      const diffHours = Math.floor(timeDiff / oneHour);

      if (timeDiff > 0) {  // Only show if trial is still active
        if (diffDays > 0) {
          setSubscriptionString(
            diffDays === 1 
              ? "1 day left free, no automatic charge" 
              : `${diffDays} days left free, no automatic charge`
          );
        } else if (diffHours > 0) {
          setSubscriptionString(
            diffHours === 1 
              ? "1 hour left free, no automatic charge" 
              : `${diffHours} hours left free, no automatic charge`
          );
        } else {
          setSubscriptionString("");
        }
      } else {
        setSubscriptionString("");
      }
    } else {
      setSubscriptionString("");
    }
  }, [plusSubscription, user]);

  useEffect(() => {
    trackEvent("/plans/visited_page");
    (async function () {
      if (user) {
        const subscriptionData = await getPlusSubscription();
        setHasLoadedPlanData(true);
        setPlusSubscription(subscriptionData);
        setIsOnPlus(user.isPlus);
        setIsPro(user.isProfessional);
      } else {
        // if no data after 1 second, show page anyways
        window.setTimeout(() => {
          setHasLoadedPlanData(true);
        }, 1000);
      }
    })();
  }, [user]);



  {useEffect(() => {
    const handleCloseModal = () => {
      setShowStripeCheckout(false);
      setIsLoadingStripe(false);
    };
    
    document.addEventListener('closeBillingModal', handleCloseModal);
    
    return () => {
      document.removeEventListener('closeBillingModal', handleCloseModal);
    };
  }, [])}


  useEffect(() => { 
    if (showStripeCheckout) { 
      // Prevent body scrolling when modal is open
      document.body.style.overflow = 'hidden'; 
    } else { 
      document.body.style.overflow = 'auto'; 
    } 
    
    return () => { 
      document.body.style.overflow = 'auto'; 
    }; 
  }, [showStripeCheckout]);

  return (
    <StyledBillingPage modalActive={showStripeCheckout}>
      {!hasLoadedPlanData ? (
        <SimpleLoadingIndicator />
      ) : (
        <>

          <Button
            onClick={() => setLocation("/")}
            transparent
            className="home-button"
            style={{
              border: "none",
              position: "fixed",
              padding: "0 16px 0 14px",
              height: "40px",
              top: "67px",
              left: "46px",
              transition: "all 0.2s ease-in-out",
              display: "flex",
              alignItems: "center",
              fontSize: "12px",
              borderRadius: "1rem",

              zIndex: 999,
            }}
          >
            <span
              style={{
                marginRight: "8px",
                fontSize: "16px",
                fontWeight: "200",
              }}
              className="arrow"
            >
              &larr;
            </span>
            <span className="text">Home</span>
          </Button>

          <div className="plan-details">
            <h1>Do tarot anytime!</h1>
            <h2>
            </h2>

            <BillingSenjaWidget />
          </div>

          <HelpButton isOnBillingPage text={"Contact us"} />
          <div className="all-plans">
            <div className={`plan plus`} style={{ position: "relative" }}>
              <img
                src="/images/moonlight_plans_screen.png"
                alt="Moonlight tarot room"
                style={{
                  width: "240px",
                  height: "auto",
                  borderRadius: "10px",
                  border: "1px solid black",
                  marginBottom: "20px",
                }}
              />

              <div
                style={{ transform: "scale(0.7)", transformOrigin: "right" }}
              >
                <SpinStar
                  style={{
                    position: "absolute",
                    right: "-30px",
                    bottom: "196px",
                  }}
                  color="#f2c889"
                  borderColor={"black"}
                />
              </div>

              <PlanInfo
                name="Unlimited Tarot Rooms"
                price=""
                description={subscriptionString}
                isPro={isPro}
              />

          <div className="plan-info">
              <h3>
                Carry the magic of tarot with you wherever life takes you. Moonlight is crafted by readers — for readers of all levels.
                </h3>
                </div>

                <Button
                style={{ fontSize: "14px", position: "relative" }}
                className="continue-button"
                onClick={(e) => {
                  //if you're not signed in, redirect to sign in page which will then redirect back to plans page after you're done
                  if (!user) {
                    setLocation("/signup?dest=/plans");
                    return;
                  }
                  if (isPro) setLocation("/");
                  else if (isOnPlus) {
                    if (isLoadingStripe) return;
                    setIsLoadingStripe(true);
                    handleCreatePortal();
                  } else {
                    if (isLoadingStripe) return;
                    trackEvent("/plans/pressed_subscribe");
                    setIsLoadingStripe(false); // Reset loading state immediately
                    setShowStripeCheckout(true);
                  }
                }}
              >
                {isLoadingStripe ? (
                  <SimpleLoadingIndicator
                    style={{
                      top: 0,
                      width: "30px",
                      height: "30px",
                      backgroundColor: "rgba(255, 255, 255, 0.3)",
                    }}
                  />
                ) : isPro ? (
                  "Continue"
                ) : isOnPlus ? (
                  "Manage plan"
                ) : (
                  "Subscribe ✨"
                )}
              </Button>


              <div className="plan-info" style={{ marginBottom: "-18px", marginTop: "15px" }}>
              <h3></h3>
              </div>

              <div className="features">
     
     <Feature
       header="Explore endlessly"
       text="4 free decks and 50+ spreads (and growing!)"
       img="/images/explore.jpg"
     />

     <Feature
       header="Read anywhere"
       text="Simple, beautiful tarot readings on any device"
       img="/images/read.jpg"
       //add isPlus if you want the word 'new' to appear
     />

     <Feature
       header="Connect deeply"
       text="Read solo for clarity or invite friends to join"
       img="/images/connect.jpg"
     />


     <Feature
       header="Hone your confidence"
       text="Interpretation tips and beginner-friendly guides"
       img="/images/confidence.jpg"
     />

     <span
       className="plus-features"
       style={{ position: "relative" }}
     ></span>
   </div>

            </div>
          </div>




          {!isOnPlus && !isPro && (
            <StyledNotes>
              <p>
                Want to extend your free trial?{" "}
                <a href="mailto:support@moonlight.world">Send a note</a>
              </p>
            </StyledNotes>
          )}

          <StyledPress>
            <a
              href="https://www.theguardian.com/technology/article/2024/aug/17/tarot-card-readers-impersonators-scammers"
              target="_blank"
            >
              <img
                src="https://moonlight.world/cdn/shop/files/The_Guardian_logo.png?v=1724874894&width=500"
                alt="The Guardian"
              />
            </a>

            <a
              href="https://news.artnet.com/art-world/danielle-baskin-moonlight-virtual-tarot-2438005"
              target="_blank"
            >
              <img
                src="https://moonlight.world/cdn/shop/files/artnet_logo.png?v=1724874894&width=500"
                alt="ArtNet"
              />
            </a>

            <a
              href="https://hyperallergic.com/833385/moonlight-tarot-platform-for-the-zoom-era/"
              target="_blank"
            >
              <img
                src="https://moonlight.world/cdn/shop/files/hyperallergic_logo.png?v=1724874894&width=500"
                alt="Hyperallergic"
              />
            </a>

            <a
              href="https://wildhunt.org/2024/06/moonlight-a-new-platform-for-tarot.html"
              target="_blank"
            >
              <img
                src="https://moonlight.world/cdn/shop/files/wild_hunt_logo.png?v=1724874894&width=500"
                alt="The Wild Hunt"
              />
            </a>
          </StyledPress>

          <ReviewSenjaWidget />

          <FAQ faqData={billingFaqData} />
        </>
      )}
      <HelpButton isOnBillingPage text={"Contact us"} />

          
      {showStripeCheckout && (
            <div 
              className="billing-modal"
              onClick={(e) => {
                // Close when clicking the backdrop (but not when clicking the modal itself)
                if (e.target === e.currentTarget) {
                  setShowStripeCheckout(false);
                  setIsLoadingStripe(false);
                }
              }}
            >
              <BillingOverlay
              onSuccess={(subscriptionId: string) => {
                console.log("successfully created Stripe subscription", subscriptionId);
                
                // Track the purchase event
                trackEvent("/plans/purchased_subscription");
                
                // Add a small delay to ensure tracking has time to complete
                setTimeout(() => {
                  setShowStripeCheckout(false); // Close the modal on success
                  setIsLoadingStripe(false);
                  window.location.href = window.location.origin + "/success";
                }, 500); // Half second delay to ensure tracking completes
              }}
              />
            </div>
          )}

    </StyledBillingPage>
  );
};

const spin = keyframes({
  "0%": {
    transform: "rotate(0deg)",
  },
  "100%": {
    transform: "rotate(360deg)",
  },
});

const StyledSenjaWidget = styled("div", {
  "@mobile": {
    width: "90%",
    maskImage: "none !important",
    WebkitMaskImage: "none !important",
  },
});

export const BillingSenjaWidget = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://widget.senja.io/widget/94cfc325-7696-4f38-9e7a-de7f177fb41a/platform.js";
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      void 0;
    };
  }, []);

  return (
    <StyledSenjaWidget>
      <div
        className="senja-embed"
        data-id="94cfc325-7696-4f38-9e7a-de7f177fb41a"
        data-mode="shadow"
        data-lazyload="false"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "18px",
          width: "100%",
        }}
      />
    </StyledSenjaWidget>
  );
};

export const ReviewSenjaWidget = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://widget.senja.io/widget/3e99fa73-939a-427f-b631-a7efa7c835c3/platform.js";
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      void 0;
    };
  }, []);

  return (
    <StyledSenjaWidget>
      <div
        className="senja-embed"
        data-id="3e99fa73-939a-427f-b631-a7efa7c835c3"
        data-mode="shadow"
        data-lazyload="false"
        style={{
          display: "block",
          width: "100%",
          maxWidth: "500px",
          margin: "0 auto",
          textAlign: "center",
          paddingTop: "50px",
          paddingBottom: "70px",
        }}
      />
    </StyledSenjaWidget>
  );
};

const billingFaqData: FAQItemProps[] = [
  {
    question: "What's included in my subscription?",
    answer:
      "Your subscription includes unlimited self-hosted rooms, access to all spread layouts, 4 free decks with meanings, custom themes, lightweight notes, solo and group sessions, and multi-platform support as we evolve.",
  },
  {
    question: "Is there a free trial available?",
    answer:
      "Yes! We offer a 7-day free trial period for new folks to try it out. If you'd like to extend your trial, just <a href='mailto:support@moonlight.world'>send a note</a>.",
  },
  {
    question: "Can I cancel my subscription?",
    answer:
      "Yes, you can cancel anytime. Your subscription will remain active until the end of your billing period.",
  },
  {
    question: "Can I use Moonlight on my phone?",
    answer:
      "Absolutely! Moonlight works on both mobile devices and desktops, giving you flexibility to do readings anywhere.",
  },
  {
    question: "What tarot decks will I get?",
    answer:
      "You get 4 free decks: Rider Waite Smith, Marseille, Summer of Bibliomancy, and Internet Lore Tarot. All the other decks are available separately in our marketplace. That way each artist gets paid! You will still need to be a member to use artist decks.",
  },
  {
    question: "Will my room guests need to subscribe?",
    answer:
      "Nope! Anyone joining your room as a guest (friends, clients, etc.) can be there free! They only need to subscribe if they want to host their own rooms.",
  },
];

const StyledPress = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "30px",
  marginTop: "20px",
  padding: "0 20px",
  width: "100%",

  "& img": {
    height: "36px",
    opacity: "1",
    filter: "contrast(1.2)",
    transition: "opacity 0.2s",
    "&:hover": {
      opacity: 0.7,
    },
  },

  "@mobile": {
    flexWrap: "wrap",
    gap: "20px",
  },
});

const StyledNotes = styled("div", {
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  fontSize: "13px",
  fontWeight: "500",
  width: "320px",
  margin: "0 auto",

  "& p": {
    marginBottom: "10px",
  },

  "& a": {
    transition: "all 0.2s ease",

    "&:hover": {
      opacity: ".6",
      textDecoration: "none",
      transform: "translateY(-1px)",
    },
  },
});

const StyledBillingPage = styled("div", {
  overflow: "auto",
  backgroundColor: "#d8b9ff",
  backgroundSize: "cover",
  paddingTop: "120px",
  paddingBottom: "calc(140px + env(safe-area-inset-bottom))",
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  variants: {
    modalActive: {
      true: {
        overflow: "hidden",
      },
      false: {
        overflow: "auto",
      },
    },
  },

  "@mobile": {
    paddingTop: "100px",
    paddingLeft: "36px",
    paddingRight: "36px",
    paddingBottom: "100px",
  },

  height: "100vh",
  display: "flex",
  flexDirection: "column",

  "& .all-plans": {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "center",
    marginTop: "40px",
    "@mobile": {
      flexDirection: "column",
    },
  },

  "& .plan-cursor img": {
    animation: `${spin} 10s linear infinite`,
  },

  "& .plan-details": {
    width: "100%",
    textAlign: "center",
    borderRadius: "32px",
    "@mobile": {
      marginTop: "24px",
    },

    "& h1": {
      fontSize: "40px",
      fontWeight: "normal",
      fontFamily: "GorgeousBaby",
      "& .i": {
        fontStyle: "italic",
      },
    },
    "& h2": {
      fontSize: "24px",
      fontFamily: "KeplerLightCondensed",
      fontWeight: "normal",
      marginTop: "10px",
      marginBottom: "10px",
      lineHeight: "1.1",
    },

    "& h3": {
      fontSize: "12px",
      fontWeight: "normal",
      marginTop: "8px",
      lineHeight: "1.1",
    },

  },

  "& .features": {
    margin: "30px auto",
    fontSize: "11px",
    color: "rgb(0, 0, 0, .7)",
    width: "100%",
    maxWidth: "600px",
    display: "flex", 
    flexDirection: "column",
    alignItems: "center",

    "& .feature": {
      width: "100%",
      alignItems: "center",
      marginBottom: "12px",

      "& .feature-line": {
        backgroundColor: "rgb(234, 189, 250, .5)",
        boxShadow: "0px 3px #000",
        border: ".5px solid rgb(0,0,0, .25)",
        display: "flex",
        borderRadius: "10px",
        padding: "20px",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        textAlign: "center",
        gap: "5px",
      },


      "& .feature-header": {
        fontSize: "13px",
        //fontFamily: "KeplerLightCondensed"
        lineHeight: "1.15em",
        fontStyle: "normal",
        fontWeight: "500",
        textTransform: "none",
        color: "#000",
      },

      "& img": {
        maxWidth: "90%",
        borderRadius: "200px 200px 8px 8px",
        border: "1px solid #000",
        marginBottom: "10px",
        filter: "saturate(.8)!important"
      },
      "& .plus": {
        borderRadius: "4px",
        backgroundColor: "$lightPurple",
        color: "black",
        border: "none",
      },
    },
  },

  "& .home-button": {
    backgroundColor: "transparent",
    height: "32px !important",
    padding: "6px 8px !important",
    borderRadius: "10px !important",
    "@mobile": {
      left: "4px !important",
      top: "55px !important",
      backgroundColor: "rgb(216, 185, 255, 0.8)",
    },
  },

  "& .plan": {
    width: "380px",
    "& button": {
      width: "200px",
      marginTop: "10px",
      transition: "all 0.2s ease-in-out",
      boxShadow: "0px 3px 0px 0px #000",
      "&:hover": {
        boxShadow: "0px 0px 0px 0px #000",
        transform: "translateY(4px)",
        opacity: 0.8,
      },
    },

    marginLeft: "8px",
    marginRight: "8px",
    "@mobile": {
      width: "100%",
      marginLeft: 0,
      marginRight: 0,
    },
    height: "100%",
    border: "1px solid black",
    borderRadius: "32px",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    "&.plus": {
      backgroundColor: "#ecdbcd",
      color: "black",
      "& img": {
        filter: "invert(0)",
      },
      "& button": {
        color: "#fff",
        backgroundColor: "#000",
        border: "1px solid #000",
        "&:hover": {
          opacity: 0.85,
        },
      },
      transition: "all 0.2s ease-in-out",
      marginBottom: "12px",
      "& .badge": {
        position: "absolute",
        right: 0,
        top: "-14px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        "& .plus-info": {
          backgroundColor: "white",
          color: "black",
          border: "1px solid black",
          padding: "6px 12px",
          borderRadius: "9px",
          fontWeight: "500",
          fontSize: "10px",
        },
      },
    },

    alignItems: "center",
    // padding: "0 50px",
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",

    "& .continue-button": {
      height: "50px !important",
    },

    "& .plan-info": {
      width: "100%",
      paddingLeft: "16px",
      paddingRight: "16px",
      marginBottom: "8px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",

      "& h1": {
        fontSize: "18px",
        textAlign: "center",
        fontWeight: "500",
        lineHeight: "1.2",
        width: "300px",
        marginBottom: "8px",
        "@mobile": {
          fontSize: "20px",
        },
      },

      "& h2": {
        fontSize: "14px",
        alignItems: "center",
        fontWeight: "400",
      },

      "& h3": {
        textAlign: "center",
       padding: "5px",
       marginTop: "10px",
        fontSize: "14px",
        fontWeight: "400",

        "@mobile": {
          fontSize: "13px",
        },
      },
      "& p": {
        fontSize: "11px",
        marginTop: "8px",
      },
    },
  },

  ".billing-modal": {
    position: "fixed",
    zIndex: "9999999!important",
    left: 0,
    right: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(60, 60, 60, 0.25)",
    paddingTop: "0px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    "& .strong": {
      fontWeight: "500",
      marginRight: "4px",
    },
    "@mobile": {
      backgroundColor: "#f6f6f6",
    }
  },
});
