import React, { useState, useEffect } from "react";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Button } from "./Button";
import {
  createDecksPaymentIntent,
  createSessionPaymentIntent,
  createPlusSubscriptionPaymentIntent,
  validatePromotionCode
} from "~/api/paymentsApi";
import { SimpleLoadingIndicator } from "~/pages/loading/LoadingPage";
import { UserProfile } from "~/api/userApi";
import { localState } from "~/state/state";
import { ms } from "date-fns/locale";
import {
  sendErrorToast,
  sendServerErrorToastWithFallbackCode,
  sendToastMessageWithFallbackCode,
} from "~/utils/handleToasts";
import { keyframes, styled } from "../style/stitches.config";


export interface CheckoutProps {
  useremail: string;
  amount: number; // amount in cents
  description: string;
  metadata: Record<string, string>;
  onSuccess: (paymentIntentId: string) => void;
  buttonStyle?: any;
  setIsSubmittingPayment: Function;
  isSubmittingPayment: boolean;
}

interface AdditionalSessionProps {
  pro: UserProfile;
  stripeConnectId: string;
}

type SessionProps = CheckoutProps & AdditionalSessionProps;

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY!);

export const StripeCheckout: React.FC<SessionProps> = (props) => {
  if (props.amount < 500) {
    console.warn(`StripeCheckout: the amount ${props.amount} is less than 500 ($5.00), which is not supported by Stripe, \
        and indicates a likely error. Please make sure you are passing the amount in cents, not dollars.`);
  }
  const options: StripeElementsOptions = {
    mode: "payment",
    amount: props.amount,
    currency: "usd",

    // Fully customizable with appearance API.
    appearance: {
      rules: {
        ".Input": {
          boxShadow: "none",
          borderRadius: "11px",
        },
        ".Input:focus": {
          border: "1px solid black",
          outline: "none",
          boxShadow: "none",
        },
        ".ApplePayButton": {
          // Choose between 'black', 'white', or 'white-outline'
          theme: "black",
        },
        ".GooglePayButton": {
          theme: "black",
        }
      },
      /*...*/
    },
    capture_method: "manual",
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm
        pro={props.pro}
        useremail={props.useremail}
        amount={props.amount}
        stripeConnectId={props.stripeConnectId}
        description={props.description}
        metadata={props.metadata}
        onSuccess={props.onSuccess}
        buttonStyle={props.buttonStyle}
        setIsSubmittingPayment={props.setIsSubmittingPayment}
        isSubmittingPayment={props.isSubmittingPayment}
      />
    </Elements>
  );
};

const CheckoutForm = ({
  pro,
  useremail,
  amount,
  description,
  metadata,
  onSuccess,
  buttonStyle,
  setIsSubmittingPayment,
  isSubmittingPayment,
}: SessionProps) => {

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: React.FormEvent) => {
    setIsSubmittingPayment(true);
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (elements == null) {
      return;
    }

    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        // If stripe provided a message, show that directly. Otherwise show our fallback error
        sendToastMessageWithFallbackCode(
          submitError.message || null,
          true,
          1144
        );
        setIsSubmittingPayment(false);
        return;
      }

      const { paymentIntentId, clientSecret } =
        await createSessionPaymentIntent({
          proId: pro.uuid,
          amount,
          description,
          metadata,
        });

      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        redirect: "if_required",
        confirmParams: {
          return_url: window.location.origin + "/booking-success",
        },
      });

      if (error) {
        // If stripe provided a message, show that directly. Otherwise show our fallback error
        sendToastMessageWithFallbackCode(error.message || null, true, 1145);
        setIsSubmittingPayment(false);
        return;
      }

      onSuccess(paymentIntentId);
    } catch (error) {
      // Else it's our server error, show our fallback error if no code provided
      sendServerErrorToastWithFallbackCode(error, 1146);
      setIsSubmittingPayment(false);
    }
  };

  return (
    <>
      <PaymentElement
        className={"sentry-mask"}
        options={{
          defaultValues: {
            billingDetails: {
              email: useremail,
            },
          },
        }}
      />
      <Button
        bookingButton
        style={buttonStyle}
        onClick={handleSubmit}
        disabled={!stripe || !elements}
      >
        {isSubmittingPayment ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
              opacity: 0.8,
              marginTop: "4px",
              transform: "scale(0.5)",
            }}
          >
            <SimpleLoadingIndicator />
          </div>
        ) : (
          "Request"
        )}
      </Button>
    </>
  );
};

/* DECKS */

export const StripeDeckCheckout: React.FC<DeckCheckoutProps> = (props) => {
  if (props.amount < 500) {
    console.warn(`StripeCheckout: the amount ${props.amount} is less than 500 ($5.00), which is not supported by Stripe, \
        and indicates a likely error. Please make sure you are passing the amount in cents, not dollars.`);
  }

  const options: StripeElementsOptions = {
    mode: "payment",
    amount: props.amount,
    currency: "usd",

    // Fully customizable with appearance API.
    appearance: {
      rules: {
        ".Input": {
          boxShadow: "none",
          borderRadius: "11px",
        },
        ".Input:focus": {
          border: "1px solid black",
          outline: "none",
          boxShadow: "none",
        },
        ".ApplePayButton": {
          // Choose between 'black', 'white', or 'white-outline'
          theme: "black",
        },
        ".GooglePayButton": {
          theme: "black",
        }
        
      },
      /*...*/
    },
    capture_method: "manual",
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <DeckCheckoutForm
        deckIds={props.deckIds}
        amount={props.amount}
        useremail={props.useremail}
        metadata={props.metadata}
        onSuccess={props.onSuccess}
        buttonStyle={props.buttonStyle}
        setIsSubmittingPayment={props.setIsSubmittingPayment}
        isSubmittingPayment={props.isSubmittingPayment}
      />
    </Elements>
  );
};

export interface DeckCheckoutProps {
  deckIds: string[];
  amount: number;
  useremail: string;
  metadata: Record<string, string>;
  onSuccess: (
    eckIds: string[],
    paymentIntentId: string,
    paymentGroupId: string
  ) => Promise<void>;
  buttonStyle?: any;
  setIsSubmittingPayment: Function;
  isSubmittingPayment: boolean;
}

const DeckCheckoutForm = ({
  deckIds,
  amount,
  useremail,
  metadata,
  onSuccess,
  buttonStyle,
  setIsSubmittingPayment,
  isSubmittingPayment,
}: DeckCheckoutProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: React.FormEvent) => {
    setIsSubmittingPayment(true);
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (elements == null) {
      return;
    }

    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        // If stripe provided a message, show that directly. Otherwise show our fallback error
        sendToastMessageWithFallbackCode(
          submitError.message || null,
          true,
          1144
        );
        setIsSubmittingPayment(false);
        return;
      }

      const { paymentIntentId, clientSecret, paymentGroupId } =
        await createDecksPaymentIntent({
          deckIds,
          metadata,
        });

      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        redirect: "if_required",
        confirmParams: {
          return_url: window.location.origin + "/booking-success",
        },
      });

      if (error) {
        // If stripe provided a message, show that directly. Otherwise show our fallback error
        sendToastMessageWithFallbackCode(error.message || null, true, 1145);
        setIsSubmittingPayment(false);
        return;
      }

      await onSuccess(deckIds, paymentIntentId, paymentGroupId);
    } catch (error) {
      // Else it's our server error, show our fallback error if no code provided
      sendServerErrorToastWithFallbackCode(error, 1146);
      setIsSubmittingPayment(false);
    }
  };

  return (
    <>
      <PaymentElement
        className={"sentry-mask"}
        options={{
          defaultValues: {
            billingDetails: {
              email: useremail,
            },
          },
        }}
      />
      <Button
        bookingButton
        style={{
          ...buttonStyle,
          pointerEvents: isSubmittingPayment ? "none" : "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "56px",
        }}
        onClick={handleSubmit}
        disabled={!stripe || !elements}
      >
        {isSubmittingPayment ? (
          <>
            <div
              style={{
                position: "relative",
                width: "40px",
                height: "60px",
                transform: "scale(0.4)",
                opacity: 0.8,
                marginTop: "8px",
              }}
            >
              <SimpleLoadingIndicator />
            </div>
            <div>
              Processing
              <StyledDot className="dot1">.</StyledDot>
              <StyledDot className="dot2">.</StyledDot>
              <StyledDot className="dot3">.</StyledDot>
            </div>
          </>
        ) : (
          "Pay now"
        )}
      </Button>
    </>
  );
};

export const SubscriptionCheckout: React.FC<SubscriptionCheckoutProps> = (props) => {  
  const options: StripeElementsOptions = {
    mode: "subscription",
    amount: props.amount,
    currency: "usd",
    appearance: {
      rules: {
        ".Input": {
          boxShadow: "none",
          borderRadius: "11px",
        },
        ".Input:focus": {
          border: "1px solid black",
          outline: "none",
          boxShadow: "none",
        },
        ".ApplePayButton": { theme: "black" },
        ".GooglePayButton": { theme: "black" },
      },
    },
    capture_method: "automatic",
    setup_future_usage: "off_session",
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <SubscriptionCheckoutForm
        useremail={props.useremail}
        amount={props.amount}
        metadata={props.metadata}
        onSuccess={props.onSuccess}
        buttonStyle={props.buttonStyle}
        setIsSubmittingPayment={props.setIsSubmittingPayment}
        isSubmittingPayment={props.isSubmittingPayment}
        discountedPrice={props.discountedPrice}
        setDiscountedPrice={props.setDiscountedPrice}
      />
    </Elements>
  );
};


export interface SubscriptionCheckoutProps {
  amount: number; // amount in cents
  useremail: string;
  metadata: Record<string, string>;
  onSuccess: (subscriptionId: string) => void;
  buttonStyle?: any;
  setIsSubmittingPayment: Function;
  isSubmittingPayment: boolean;
  discountedPrice: number; // ✅ Add this
  setDiscountedPrice: React.Dispatch<React.SetStateAction<number>>;
}

const SubscriptionCheckoutForm = ({
  amount,
  useremail,
  metadata,
  onSuccess,
  buttonStyle,
  setIsSubmittingPayment,
  isSubmittingPayment,
  discountedPrice,
  setDiscountedPrice,
}: SubscriptionCheckoutProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [promoCode, setPromoCode] = useState("");
  const [appliedPromo, setAppliedPromo] = useState<string | null>(null);
  const [promoError, setPromoError] = useState("");

  // promo code functions
  const handlePromoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromoCode(e.target.value);
    setPromoError("");
    if (appliedPromo) {
      setAppliedPromo(null);
    }
  };

  const [isValidatingPromo, setIsValidatingPromo] = useState(false);
  const [discountInfo, setDiscountInfo] = useState<{
    percentOff?: number;
    amountOff?: number;
    duration?: 'once' | 'forever' | 'repeating';
    durationInMonths?: number | null;
    maxRedemptions?: number | null;
    appliesToSubscriptions?: boolean;
  } | null>(null);
  

  const [isPaymentFieldsReady, setIsPaymentFieldsReady] = useState(false);


  const applyPromoCode = async () => {
    if (!promoCode.trim()) {
        setPromoError("Please enter an offer code");
        return;
    }

    setIsValidatingPromo(true);

    try {
        const response = await validatePromotionCode(promoCode.trim());
        
        if (response.valid) {
            setAppliedPromo(promoCode.trim());
            setPromoError("");
            // Fix: Set to null if discountInfo is undefined
            setDiscountInfo(response.discountInfo || null);

            // Update discounted price using prop from BillingOverlay
            if (response.discountInfo?.percentOff) {
                setDiscountedPrice(amount - (amount * response.discountInfo.percentOff) / 100);
            } else if (response.discountInfo?.amountOff) {
                setDiscountedPrice(Math.max(0, amount - response.discountInfo.amountOff));
            }
        } else {
            setPromoError(response.message || "Invalid code");
            setAppliedPromo(null);
        }
    } catch (error: any) {
        setPromoError(error.response?.data?.message || "Error validating code. Try again.");
        console.error("Error validating promotion code:", error);
    } finally {
        setIsValidatingPromo(false);
    }
};;



  // Update your handleSubmit function to use the updated paymentsApi function
  const handleSubmit = async (event: React.FormEvent) => {
    setIsSubmittingPayment(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (elements == null) {
      return;
    }

    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        sendToastMessageWithFallbackCode(
          submitError.message || null,
          true,
          1144
        );
        setIsSubmittingPayment(false);
        return;
      }

      // Pass the promotion code to the backend if one is applied
      const { subscriptionId, clientSecret } = await createPlusSubscriptionPaymentIntent(
        appliedPromo ? { promotionCode: appliedPromo } : undefined
    );
    
      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        redirect: "if_required",
        confirmParams: {
          return_url: window.location.origin + "/success",
        },
      });

      if (error) {
        // Check if error is related to the promotion code
        if (error.message && 
            (error.message.toLowerCase().includes('promotion') || 
             error.message.toLowerCase().includes('coupon'))) {
          setPromoError(error.message);
          setAppliedPromo(null);
          setIsSubmittingPayment(false);
          return;
        }
        
        // Other payment errors
        sendToastMessageWithFallbackCode(error.message || null, true, 1146);
        setIsSubmittingPayment(false);
        return;
      }
      
      await onSuccess(subscriptionId);
    } catch (error: unknown) {
      // First check if error is an object with a message property
      if (error && typeof error === 'object' && 'message' in error) {
        const errorMessage = error.message;
        
        // Now check if the message is related to promotion codes
        if (typeof errorMessage === 'string' && 
            (errorMessage.toLowerCase().includes('promotion') || 
             errorMessage.toLowerCase().includes('coupon'))) {
          setPromoError(errorMessage);
          setAppliedPromo(null);
          setIsSubmittingPayment(false);
          return;
        }
      }
      
      // Handle other errors
      sendServerErrorToastWithFallbackCode(error, 1147);
      setIsSubmittingPayment(false);
    }
    
  };

  return (
    <>
      <PaymentElement
        className={"sentry-mask"}
        options={{
          defaultValues: {
            billingDetails: {
              email: useremail,
            },
          },
          terms: {
            card: "never",
          },
        }}
        onReady={() => {
          setIsPaymentFieldsReady(true);
        }}
      />

      {/* Promotion Code Section */}
      {isPaymentFieldsReady && (
      <div style={{ marginTop: "20px", marginBottom: "4px" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div style={{ 
            position: "relative", 
            flex: 1,
            display: "flex",
            alignItems: "center"
          }}>
            <input
              type="text"
              placeholder="offer code"
              value={promoCode}
              onChange={handlePromoChange}
              style={{ 
                width: "100%",
                backgroundColor: "transparent",
                padding: "5px 0px",
                borderBottom: "1px solid #d5d5d5",
                outline: "none",
                marginRight: "4px"
              }}
              disabled={isSubmittingPayment || !!appliedPromo}
            />
          </div>
          <Button
            onClick={applyPromoCode}
            disabled={!promoCode.trim() || isSubmittingPayment || !!appliedPromo || isValidatingPromo}
            style={{
              padding: "8px 18px",
              width: "100px",
              backgroundColor: "#e9e9e9",
              border: "none",
              height: "30px",
              fontSize: "11px",
            }}
          >
            {isValidatingPromo ? "checking..." : appliedPromo ? "applied ✓" : "apply"}
          </Button>
        </div>
        
        {(promoError || appliedPromo) && (
          <div style={{ 
            display: "flex", 
            alignItems: "center",
            marginTop: "5px",
            position: "relative",
            paddingRight: "100px" // Width of the apply button
          }}>
            <div style={{ color: promoError ? "#f3351e" : "#5d5d5d", fontSize: "11px" }}>
              {promoError ? promoError : (
                <span>
                  {discountInfo?.percentOff
                    ? `${discountInfo.percentOff}% off`
                    : discountInfo?.amountOff
                    ? `$${discountInfo.amountOff / 100} off`
                    : "Code applied"}

                  {discountInfo?.duration === 'once' && " (one-time use)"}
                  {discountInfo?.duration === 'forever' && " (recurring discount)"}
                  {discountInfo?.duration === 'repeating' && discountInfo.durationInMonths
                    ? ` (valid for ${discountInfo.durationInMonths} ${discountInfo.durationInMonths === 1 ? 'month' : 'months'})`
                    : ""}
                </span>
              )}
            </div>
            
            <div
              onClick={() => {
                setAppliedPromo(null);
                setPromoCode("");
                setDiscountInfo(null);
                setDiscountedPrice(amount);
                setPromoError("");
              }}
              style={{
                color: "#5d5d5d",
                fontSize: "11px",
                cursor: "pointer",
                textDecoration: "underline",
                position: "absolute",
                right: "0",
                width: "100px",
                textAlign: "center"
              }}
            >
              remove
            </div>
          </div>
        )}
      </div>
      )}
  
      {/* End of Promotion Code Section */}

      <Button
        bookingButton
        style={{
          ...buttonStyle,
          pointerEvents: isSubmittingPayment ? "none" : "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "56px",
        }}
        onClick={handleSubmit}
        disabled={!stripe || !elements}
      >
        {isSubmittingPayment ? (
          <>
            <div
              style={{
                position: "relative",
                width: "40px",
                height: "60px",
                transform: "scale(0.4)",
                opacity: 0.8,
                marginTop: "8px",
              }}
            >
              <SimpleLoadingIndicator />
            </div>
            <div>
              Processing
              <StyledDot className="dot1">.</StyledDot>
              <StyledDot className="dot2">.</StyledDot>
              <StyledDot className="dot3">.</StyledDot>
            </div>
          </>
        ) : (
          "Subscribe now"
        )}
      </Button>
    </>
  );
};




const dot1Animation = keyframes({
  "0%": { opacity: 0 },
  "60%": { opacity: 1 },
  "100%": { opacity: 1 },
});

const dot2Animation = keyframes({
  "20%": { opacity: 0 },
  "80%": { opacity: 1 },
  "100%": { opacity: 1 },
});

const dot3Animation = keyframes({
  "40%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const StyledDot = styled("span", {
  opacity: 0,
  marginLeft: "1px",
  "&.dot1": {
    animation: `${dot1Animation} 1.5s infinite`,
  },
  "&.dot2": {
    animation: `${dot2Animation} 1.5s infinite`,
  },
  "&.dot3": {
    animation: `${dot3Animation} 1.5s infinite`,
  },
});
