import { useContext, useState, useEffect } from "react";
import { SecurePaymentsDisclaimer } from "~/ui/components/DisclaimerContainer";
import { SubscriptionCheckout } from "~/ui/components/StripeCheckout";
import { styled } from "~/ui/style/stitches.config";
import { AuthContext } from "~/utils/useAuth";
import { useSnapshot } from "valtio";
import { localState } from "~/state/state";
export const BillingOverlay = ({
  onSuccess,
}: {
  onSuccess: (subscriptionId: string) => void;
}) => {
  const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);
  const { user } = useContext(AuthContext);
  const { isOnMobile } = useSnapshot(localState);
  const [discountedPrice, setDiscountedPrice] = useState(11 * 100);

  const amount = 11 * 100; 

  useEffect(() => {
    const originalOverflow = document.body.style.overflow;
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, []);

  return (
    <StyledBillingOverlay>
      <CloseButton>
        <img
          src="/images/room/x-icon.png"
          className="x-button"
          onClick={(e) => {
            const closeEvent = new CustomEvent("closeBillingModal");
            document.dispatchEvent(closeEvent);
            e.stopPropagation();
          }}
        />
      </CloseButton>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div className="top-container">
          <div className="title">Become a Moonlight subscriber ✨</div>
          <div className="subtitle">Billed monthly. Cancel anytime.</div>
          <div className="total-line">
            <div className="total-label">Total</div>
            <div className="total-price">
              {discountedPrice !== amount ? (
                <>
                  <span
                    style={{
                      textDecoration: "line-through",
                      color: "#888",
                      marginRight: "8px",
                    }}
                  >
                    ${(amount / 100).toFixed(2)} USD
                  </span>
                  <span style={{ color: "#000" }}>
                    ${(discountedPrice / 100).toFixed(2)} USD
                  </span>
                </>
              ) : (
                `$${(amount / 100).toFixed(2)} USD`
              )}
            </div>
          </div>
        </div>

        <SubscriptionCheckout
          useremail={user?.email || ""}
          amount={amount} // ✅ Now we pass `amount` correctly
          metadata={{
            requesterId: user?.uuid || "",
            requesterUsername: user?.username || "",
          }}
          buttonStyle={{
            backgroundColor: "#efc1ff",
            fontSize: "15px",
            height: "52px",
            marginTop: "24px",
          }}
          isSubmittingPayment={isSubmittingPayment}
          setIsSubmittingPayment={setIsSubmittingPayment}
          onSuccess={onSuccess}
          discountedPrice={discountedPrice}
          setDiscountedPrice={setDiscountedPrice}
        />
      </div>

      <SecurePaymentsDisclaimer
        text="You can access rooms immediately after payment."
        style={{
          fontSize: "11px",
          textWrap: isOnMobile ? "wrap" : "nowrap",
          marginTop: "10px",
          marginBottom: isOnMobile ? "50px" : "0px",
        }}
      />
    </StyledBillingOverlay>
  );
};




const CloseButton = styled("div", {
  // Base styles that apply to all screen sizes
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  position: "absolute",
  top: "20px",
  right: "20px",
  width: "20px",
  height: "20px",
  zIndex: 99999999,
  
  // Mobile-specific styles
  "@mobile": {
    position: "fixed",
    top: 46,
    left: 10,
    width: "100%",
    height: "56px",
    backgroundColor: "$wash",
    
    "& .x-button": {
      width: "20px",
      height: "20px",
      filter: "invert(1)",
      cursor: "pointer",
      opacity: "0.8",
      marginRight: "20px",
      transform: "scale(1.4)",
    },
  },
});


const StyledBillingOverlay = styled("div", {
  width: "500px",
  zIndex: "99999999!important",
  backgroundColor: "$wash",
  padding: "70px 60px",
  border: "1px solid black",
  borderRadius: "33px",
  position: "fixed",
  "& .x-button": {
    position: "absolute",
    top: "20px",
    right: "20px",
    width: "20px",
    height: "20px",
    filter: "invert(1)",
    cursor: "pointer",
    opacity: "0.8",
    "&:hover": {
      opacity: "0.5",
    },
    zIndex: 99999999,
  },
  maxHeight: "90vh",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    webkitAppearance: "none",
    width: "8px",
    marginLeft: "1px",
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "20px",
    backgroundColor: "rgba(0, 0, 0, .5)",
    border: "1px solid $wash",
  },
  "&::-webkit-scrollbar-track": {
    overflow: "hidden",
    marginTop: "60px",
    marginBottom: "30px",
    width: "8px",
  },
  "& .top-container": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "left",
    width: "100%",
    "& .title": {
      fontSize: "15px",
      "@mobile": {
        fontSize: "16px",
      },
      fontWeight: "500",
    },
    "& .subtitle": {
      fontSize: "13px",
      "@mobile": {
        fontSize: "14px",
        marginBottom: "10px",
      },
      marginTop: "3px",
      width: "101%",
    },
    "& .total-line": {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      marginTop: "20px",
      marginBottom: "30px",
      fontSize: "18px",

      "& .total-price": {
        fontWeight: "400",
      },
    },
  },
  "@mobile": {
    width: "100vw",
    height: "calc(100vh - 50px)",
    padding: "70px 30px 200px 30px",
    maxHeight: "none",
    margin: 0,
    borderRadius: 0,
    border: "none",
    overflowY: "auto", 
    overflowX: "hidden",
    position: "fixed !important",
    top: "50px", 
    left: 0,
    zIndex: 99999999,
  },
});

